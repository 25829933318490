/****************************/
.container-forgot-password {
  max-width: 325px; /*change*/
  padding: 2em;
  background-color: #798EAF;
  margin: auto;
  border-radius: 15px;
  text-align: center;
  font-size: 16px;
}

.icon-pass, .icon-pass-green {
  border-radius: 100%;
  border: 2px solid #FFF;
  padding: 1em;
  width: 25px;
  height: 25px;
  margin: auto;
  font-size: 25px;
  color: #FFF;
}

.icon-pass-green {
  border: 2px solid #63d697;
  color: #63d697;
}

/* Container */

.container-forgot-password input {
  border: 1px solid #FFF;
  padding: 12px 15px;
  font-size: 18px;
  box-sizing : border-box;
  width: 100%;
  margin: 0.5em 0;
  border-radius: 12px;
}

.container-forgot-password input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
color: #798EAF;
opacity: 1; /* Firefox */
}

.container-forgot-password input:-ms-input-placeholder { /* Internet Explorer 10-11 */
color: #798EAF;
}

.container-forgot-password input::-ms-input-placeholder { /* Microsoft Edge */
color: #798EAF;
}

.container-forgot-password .container-login_btn-login {
  margin-top: 1.5em;
}

.container-login_btn-login-secondary {
  font-weight: bold;
  display: block;
  border-radius: 4px;
  padding: 10px 0;
  font-size: 16px;
  text-align: center;
  margin: 0.5em auto;
  background-color: #27466c;
  border: 1px solid #27466c;
  color: #FFF;
  width: 100%;
  cursor: pointer;
}

.container-login_btn-login-secondary:hover {
  background-color: #b3bcbf;
  border: 1px solid #b3bcbf;
}


.container-forgot-password  .text-login {
  color: #FFF;
  margin: 1.5em auto;    
}

.important-message {
  background-color: #ffde9c;
  color: #233b58;
  font-size: 13px;
  padding: 1em;
  border-radius: 10px;
}

.important-message i {
  margin: 0.5em;
  display: block;
}


