a {cursor: pointer;}
.button-fullscreen {    
  position: fixed;     
  bottom: 0;
  right: 0;
  background-color: #65A142;
  padding: 0.5em 1em;
  font-size: 14px;
  color: #CFF8B6;  
  cursor: pointer;
  z-index: 2000;
}

.button-fullscreen span { 
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  padding: 0 5px;
}

.button-fullscreen:hover {    
  color: #8C9096;
  background-color: #D3D5DB;
}

.content-dropzone {
  text-align: left;
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 10px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: transparent;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  color: #666;
  cursor: pointer;
}

.content-dropzone:hover {
  color: #172B4D;
}

.main-dropzone {
  margin-top: 10px;
  margin-bottom: 10px;
}

.content-dropzone i {
  padding-right: 10px;
  font-size: 18px;
  color: #172B4D;
}

.fileSelected-caption {
  font-size: 12px;
  color: #9fa6b1;
  letter-spacing: normal;
}

.files-attached-list {
  border-bottom: 1px solid #DDD;
  margin-bottom: 0.7em;
  font-size: 13px;
}

.files-attached-list i {
  color: #dc3545;
  margin-right: 8px;
  cursor: pointer;
}

.files-attached-list i:hover {
  color: #c79397;
}

:focus {outline: none;}
/* Input Select */

.selectTrello .css-yk16xz-control {
	border: 0;
	background: transparent;
	font-size: 13px;
	margin-bottom: 0.25em;
	border-bottom: 1px solid #D3D5DB;
	border-radius: 0;
}

.selectTrello .css-1pahdxg-control {
	border: 0;
	background: transparent;
	font-size: 13px;
	margin-bottom: 0.25em;
	border-bottom: 1px solid #D3D5DB;
	border-radius: 0;
	box-shadow:0 0 0 0px #D3D5DB;
} 

.selectTrello .css-1okebmr-indicatorSeparator {
	display: none;
}

.selectTrello .css-1rhbuit-multiValue {
    background-color: transparent;
    font-weight: bold;
}

.selectTrello .css-xb97g8:hover {
    background-color: transparent;
    color: #2298BD;
}


/* Close Windows */

button.close-windows {
	padding: 0 0 1em 0.5em; 
	border: 0;
	color: #898D93;
	background-color: transparent;
}

button.close-windows:hover { 
	color: #2298BD;
}

button, .btn {cursor: pointer;}

/*Basics Style*/
.text-right {text-align: right;}


/* Principal Scroll Bar */
.react-draggable::-webkit-scrollbar-track {	  
	border-radius: 10px;
	background-color: #ebecf0;
}
  
.react-draggable::-webkit-scrollbar {
	width: 12px;
	background-color: #F5F5F5;
}
  
.react-draggable::-webkit-scrollbar-thumb  {
	border-radius: 10px;	 
	background-color: #dadce0;
}

.cards-panelTrello-description {
  background: #FFF;
  color: #172B4D;
  padding: 15px;
  width: 90%;
  height: 130px;
  overflow-y: scroll;
  border: 1px solid #E8E8E8;
  font-family: 'Roboto' , Arial , Helvetica , sans-serif;
  margin-bottom: 1em;
}

.cards-panelTrello-description p {
	margin: 0 0 0.5em 0;
}

.cards-panelTrello-description a {
	color: #2298BD;
}

.cards-panelTrello {
	font-size: 13px;
	margin-bottom: 2em;
	border-bottom: 1px solid #E8E8E8;
}

.cards-panelTrello .Members {
	font-weight: bold;
	color: #68758A;
	font-size: 9px;
	letter-spacing: 0.5px;
	margin-top: 10px;
	text-transform: uppercase;
}

.cards-panelTrello .Members span {
	margin-right: 10px;
}

.cards-panelTrello h1 {
	font-size: 13px;
}
