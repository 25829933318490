@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* TOAST */
.redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center, .redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
  font-size: 12px;
}

.redux-toastr .toastr {
  min-height: 47px;
}

.redux-toastr .toastr .close-toastr {
  /* opacity: 0.8;
  color: #fff; */
  font-weight: bold;
  width: 14%;
}

.redux-toastr .toastr .rrt-left-container .rrt-holder {
  width: 50px;
  height: 50px;
}

.redux-toastr .toastr .rrt-left-container {
  width: 50px;
}

.redux-toastr .toastr .rrt-middle-container {
  width: 67%;
}