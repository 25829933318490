@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* TOAST */
.redux-toastr .top-left, .redux-toastr .top-right, .redux-toastr .top-center, .redux-toastr .bottom-left, .redux-toastr .bottom-right, .redux-toastr .bottom-center {
  font-size: 12px;
}

.redux-toastr .toastr {
  min-height: 47px;
}

.redux-toastr .toastr .close-toastr {
  /* opacity: 0.8;
  color: #fff; */
  font-weight: bold;
  width: 14%;
}

.redux-toastr .toastr .rrt-left-container .rrt-holder {
  width: 50px;
  height: 50px;
}

.redux-toastr .toastr .rrt-left-container {
  width: 50px;
}

.redux-toastr .toastr .rrt-middle-container {
  width: 67%;
}
a {cursor: pointer;}
.button-fullscreen {    
  position: fixed;     
  bottom: 0;
  right: 0;
  background-color: #65A142;
  padding: 0.5em 1em;
  font-size: 14px;
  color: #CFF8B6;  
  cursor: pointer;
  z-index: 2000;
}

.button-fullscreen span { 
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  padding: 0 5px;
}

.button-fullscreen:hover {    
  color: #8C9096;
  background-color: #D3D5DB;
}

.content-dropzone {
  text-align: left;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  padding: 10px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: transparent;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  color: #666;
  cursor: pointer;
}

.content-dropzone:hover {
  color: #172B4D;
}

.main-dropzone {
  margin-top: 10px;
  margin-bottom: 10px;
}

.content-dropzone i {
  padding-right: 10px;
  font-size: 18px;
  color: #172B4D;
}

.fileSelected-caption {
  font-size: 12px;
  color: #9fa6b1;
  letter-spacing: normal;
}

.files-attached-list {
  border-bottom: 1px solid #DDD;
  margin-bottom: 0.7em;
  font-size: 13px;
}

.files-attached-list i {
  color: #dc3545;
  margin-right: 8px;
  cursor: pointer;
}

.files-attached-list i:hover {
  color: #c79397;
}

:focus {outline: none;}
/* Input Select */

.selectTrello .css-yk16xz-control {
	border: 0;
	background: transparent;
	font-size: 13px;
	margin-bottom: 0.25em;
	border-bottom: 1px solid #D3D5DB;
	border-radius: 0;
}

.selectTrello .css-1pahdxg-control {
	border: 0;
	background: transparent;
	font-size: 13px;
	margin-bottom: 0.25em;
	border-bottom: 1px solid #D3D5DB;
	border-radius: 0;
	box-shadow:0 0 0 0px #D3D5DB;
} 

.selectTrello .css-1okebmr-indicatorSeparator {
	display: none;
}

.selectTrello .css-1rhbuit-multiValue {
    background-color: transparent;
    font-weight: bold;
}

.selectTrello .css-xb97g8:hover {
    background-color: transparent;
    color: #2298BD;
}


/* Close Windows */

button.close-windows {
	padding: 0 0 1em 0.5em; 
	border: 0;
	color: #898D93;
	background-color: transparent;
}

button.close-windows:hover { 
	color: #2298BD;
}

button, .btn {cursor: pointer;}

/*Basics Style*/
.text-right {text-align: right;}


/* Principal Scroll Bar */
.react-draggable::-webkit-scrollbar-track {	  
	border-radius: 10px;
	background-color: #ebecf0;
}
  
.react-draggable::-webkit-scrollbar {
	width: 12px;
	background-color: #F5F5F5;
}
  
.react-draggable::-webkit-scrollbar-thumb  {
	border-radius: 10px;	 
	background-color: #dadce0;
}

.cards-panelTrello-description {
  background: #FFF;
  color: #172B4D;
  padding: 15px;
  width: 90%;
  height: 130px;
  overflow-y: scroll;
  border: 1px solid #E8E8E8;
  font-family: 'Roboto' , Arial , Helvetica , sans-serif;
  margin-bottom: 1em;
}

.cards-panelTrello-description p {
	margin: 0 0 0.5em 0;
}

.cards-panelTrello-description a {
	color: #2298BD;
}

.cards-panelTrello {
	font-size: 13px;
	margin-bottom: 2em;
	border-bottom: 1px solid #E8E8E8;
}

.cards-panelTrello .Members {
	font-weight: bold;
	color: #68758A;
	font-size: 9px;
	letter-spacing: 0.5px;
	margin-top: 10px;
	text-transform: uppercase;
}

.cards-panelTrello .Members span {
	margin-right: 10px;
}

.cards-panelTrello h1 {
	font-size: 13px;
}

.btn-Microsoft {
    font-weight: 700;
    display: block;
    border-radius: 4px;
    padding: 10px 0;
    font-size: 16px;
    text-align: center;
    margin: .5em auto;
    background: #FFF;
    border: 1px solid #737373;
    color: #737373;
    width: 100%;
    cursor: pointer;
}

.btn-Microsoft:hover {background: #F9F9F9;}

.btn-Microsoft img {width: 20px; vertical-align: middle; margin-right: 5px;}
.main-header--dashboard {
  background: #27466C;
  padding: 0.8em 1em;
  display: flex;
  align-items: center;
      position: relative; 
}

.main-header_GenialSkills-logo--dashboard {
  width: 20%;
}

.main-header_GenialSkills-logo--dashboard img {
  width: 85px;
}

.noticesDev {
  font-size: .8em;
  background: #FF6483;
  padding: 5px 8px; 
  color: #FFF;
  text-align: center;
  font-weight: bold;
  border-radius: 50px;
  margin-left: 10px;
}

.noticesBeta{
  font-size: .8em;
  background: #FFC164;
  padding: 5px 8px; 
  color: #FFF;
  text-align: center;
  font-weight: bold;
  border-radius: 50px;
  margin-left: 10px;

}

/* Top Bar */

.main-header_top-bar-menu--dashboard {
  width: 90%;
  text-align: right;
}

.top-bar-menu--dashboard {
  font-size: 18px;
  list-style: none;
}

.top-bar-menu--dashboard li {
  padding: 0 0.5em;
}

.top-bar-menu--dashboard li, .top-bar-menu--dashboard li img {
  display: inline-block;
  vertical-align: middle;
}

.top-bar-menu--dashboard li .fas {
  margin-right: 10px;
  font-size: 25px;
  color: #FFF;
}

.top-bar-menu--dashboard li a {
  color: #FFF;
  text-decoration: none;
}

.top-bar-menu--dashboard li a:hover {
  color: #FCAD2F;
}

.top-bar-menu--dashboard_select select {
  box-sizing: content-box;
  background: transparent;
  border: 0;
  outline: 0;
  color: #FFF;
  padding: 0 0.2em 0 0;
  font-size: 18px;
  cursor: pointer;
  -webkit-appearance: none;
}

.top-bar-menu--dashboard_select select:hover {
  color: #FCAD2F;
}



/* MQ 750px */

@media screen and (max-width: 750px) {
  .main-header_GenialSkills-logo--dashboard img { width: 85px; }

  .text-disabled {display: none;}

  .top-bar-menu--dashboard { font-size: 14px; }

  .top-bar-menu--dashboard li .fa-question-circle { font-size: 22px; }

  .top-bar-menu--dashboard li .fa-globe { font-size: 15px; }

  .top-bar-menu--dashboard_select select { font-size: 14px; }
}

/* MQ 500px */

@media screen and (max-width: 500px) {
  .main-header--dashboard {
flex-wrap: wrap;
  }

  .main-header_top-bar-menu--dashboard {
    width: 100%;
    text-align: center;
    border-top: 1px solid #212B75;
    padding-top: 0.25em;
    margin-top: 0.25em;
  }

  .main-header_GenialSkills-logo--dashboard { width: 100%; text-align: center; }
  
  .main-header_GenialSkills-logo--dashboard img {  width: 80px; }

  .top-bar-menu--dashboard li .fa-globe { display: none; }

  .main-header--dashboard {padding: 1em 0.25em;}
}


.wrapper-container-login {
  background: url(/static/media/bg-login.6e619523.jpg) center top no-repeat;
  background-size: cover;
  padding: 3em 0 5em 0;
}

/****************************/
.container-login {
  max-width: 725px; /*change*/
  padding: 1em;
  background-color: #798EAF;
  margin: auto;
  border-radius: 15px;
  text-align: center;
  font-size: 16px; 
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
}

/* Avatar */
.container-login_double-border {
  border-radius: 50%;
  background-color: transparent;
  border: solid 4px transparent;
  padding: 3px;
  width: 85px;
  height: 85px;
  display: inline-block;
  vertical-align: middle;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #B5DFE9 50%,#E88B19 50%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.container-login_double-border img {
  border-radius: 50%;
  width: 85px;
  box-shadow: 0 0 0 3px #798EAF;
}

/* Container */

.container-login_title {
  font-size: 20px;
  color: #FFF;
  margin-top: 1em;
}

.container-login input {
  border: 1px solid #FFF;
  padding: 12px 15px;
  font-size: 18px;
  box-sizing : border-box;
  width: 100%;
  margin: 0.5em 0;
  border-radius: 12px;
}

.container-login input::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
color: #798EAF;
opacity: 1; /* Firefox */
}

.container-login input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
color: #798EAF;
opacity: 1; /* Firefox */
}

.container-login input:-ms-input-placeholder { /* Internet Explorer 10-11 */
color: #798EAF;
}

.container-login input::-ms-input-placeholder { /* Microsoft Edge */
color: #798EAF;
}

.container-login_btn-login {
  font-weight: bold;
  display: block;
  border-radius: 4px;
  padding: 10px 0;
  font-size: 16px;
  text-align: center;
  margin: 0.5em auto;
  background-color: #F2AF2B;
  border: 1px solid #F2AF2B;
  color: #FFF;
  width: 100%;
  cursor: pointer;
}

.container-login_btn-login:hover {
  background-color: #b3bcbf;
  border: 1px solid #b3bcbf;
}

.container-login_checkbox input {
  width: auto;
  cursor: pointer;
}

.container-login_checkbox {
  text-align: left;
  color: #FFF;    
}

.container-login_checkbox label {
  padding-left: 0.5em;
  cursor: pointer;
}

a.container-login_txt-secondary {
  margin-top: 2em;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  color: #F5C362;
  text-decoration: none;
}

a.container-login_txt-secondary:hover {
  text-decoration: underline;
}

/*new*/
.col-login-left {
  width: 48%;
}

.col-login-right {
  width: 48%;
  background-color: #EFF0F0;
  border-radius: 15px;
}

.module-w-padding {
  padding: 2em;
}

.module-login--teacher, 
.module-login--subscribe, 
.module-login--institutional {
  padding: 0.25em 1.5em 1em 1.5em;
}

.module-login--teacher {
  background-color: #E5E5E5;
}

.container-login .col-login-right h1 {
  color: #233B58;
  font-size: 18px;
  font-weight: normal;
  margin: 0.25em;
}

.container-login .col-login-right a {
  font-size: 15px;
  color: #5A6576;
  font-weight: bold;
}

.container-login .col-login-right .txt-small {
  font-size: 12px;
  margin: 0.8em 0 0 0;
  padding: 0;
  color: #233B58;
}

.module-login--institutional p {
  font-size: 12px;
}

.container-login .col-login-right img {
  width: 80px;
  height: auto;
  vertical-align: top;
}


/* MQ 750px */

@media screen and (max-width: 750px) {
  .container-login { max-width: 85%; }
  .container-login_double-border { width: 80px; height: 80px; }
  
  .container-login_double-border img { width: 80px; }

  .container-login_title { font-size: 16px; margin-top: 0.5em;}
  
  .container-login input { padding: 10px 10px; font-size: 15px; }

  .container-login_btn-login { font-size: 14px; }

  .container-login_txt-secondary, .container-login_checkbox label { font-size: 14px;}
}

/* MQ 600px */

@media screen and (max-width: 600px) {
  .col-login-left, .col-login-right {
       width: 100%;
   }

   .module-w-padding {
       padding: 0.5em 2em;
   }
}

/* MQ 500px */

@media screen and (max-width: 500px) {   
   .container-login .col-login-right h1 { font-size: 16px; }
   .container-login .col-login-right img { width: 60px }
}

/* MQ 450px */

@media screen and (max-width: 450px) {
   .module-w-padding { padding: 0.5em 1em; }
}
.wrapper-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
}

/****************************/

.main-footer {
  background-color: #2C3140;
  color: #FFF;
  font-size: 16px;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

/* Footer Principal */

.footer-languages-login p {
  margin: 0.25em;
}

.footer-languages-login {
  padding: 0.5em;
}

.footer-languages-login .footer-languages_languages {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-languages-login .footer-languages_languages li {
  display: inline-block;
  padding: 0 0.25em;
  vertical-align: middle;
}

.footer-languages-login .footer-languages_languages li img {
  width: 25px;
}

.footer-languages-login .footer-languages_languages li a {
  color: #FFF;
  text-decoration: underline;
  cursor: pointer;
}

.footer-languages-login .footer-languages_languages li a:hover {
  text-decoration: none;
}

/* Footer Secondary COPYRIGHT */

.footer-copyright {
  background-color: #13192E;
  font-size: 12px;
  padding: 0.5em;
}

.footer-copyright p {
  margin: 0.3em;
}

.footer-copyright a {
  color: #FFF;
  text-decoration: underline;
}

.footer-copyright a:hover {
  text-decoration: none;
}

.red-link a {
  color: #EF1A1A
}

.footer-copyright a {
  cursor: pointer;
}

/* MQ 750px */

@media screen and (max-width: 750px) {
  .footer-languages-login ul, .footer-languages-login p {
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;
      width: 48%;
  }

  .footer-languages-login ul {text-align: left;}
  .footer-languages-login p {text-align: right; }
  .footer-languages-login {  padding: 0.25em; }
}

/* MQ 500px */

@media screen and (max-width: 500px) {
  .footer-languages-login .footer-languages_languages span, 
  .footer-languages-login .footer-languages_languages .fas { display: none;}
  .footer-languages-login .footer-languages_languages { text-transform: uppercase;}

  .footer-languages-login ul {width: 29%;}
  .footer-languages-login p {width: 68%; }
}

.wrapper-header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
}

/****************************/

.main-header {
  background: #27466C;
  padding: 0.8em 1em;
  display: flex;
  align-items: center;
      position: relative;
}

.main-header_GenialSkills-logo {
  width: 20%;
}

.main-header_GenialSkills-logo img {
  width: 85px;
}

.noticesDev {
  font-size: .8em;
  background: #FF6483;
  padding: 5px 8px; 
  color: #FFF;
  text-align: center;
  font-weight: bold;
  border-radius: 50px;
  margin-left: 10px;
}

.noticesBeta{
  font-size: .8em;
  background: #FFC164;
  padding: 5px 8px; 
  color: #FFF;
  text-align: center;
  font-weight: bold;
  border-radius: 50px;
  margin-left: 10px;

}


/* Top Bar */

.main-header_top-bar-menu {
  width: 80%;
  text-align: right;
}

.top-bar-menu {
  margin-right: 230px;
  font-size: 16px;
  list-style: none;
}

.top-bar-menu li, .top-bar-menu li img {
  display: inline-block;
  vertical-align: middle;
}

.top-bar-menu li .fas {
  margin-right: 5px;
  font-size: 20px;
  color: #FFF;
  vertical-align: middle;
}

.top-bar-menu li a {
  color: #FFF;
  text-decoration: none;
}

.top-bar-menu li a:hover {
  color: #FCAD2F;
}

.top-bar-menu .MuiButton-root {
  color: #FFF;
  text-transform: none;
  font-size: 16px;
}

.MuiMenuItem-root a {
  text-decoration: none;
  color: #000000DE;  
}


/* Btn General */
.user-comp_profile-wrapper {
  width: 225px;
  font-family: 'Roboto' , Arial , Helvetica , sans-serif;
  position: absolute;
  top: 5px;
  right: 10px;
  margin: 0;
  padding: 0;
  text-align: left;    
}

.user-comp_profile-wrapper li {
  list-style: none;
}

.user-comp_profile {
  padding: 5px;
}
.user-comp_profile:hover {
  cursor:pointer;
}

.user-menu-icon {
  display: inline-block;
  vertical-align: middle;
  padding-right: 0.5em;
}

.user-comp_profile .user-name {
  font-size: 18px;
  color: #FFF;
  line-height: 20px;
  margin-left: 10px;
  font-weight: bold;
}

/* Avatar Principal */
.double-border--principal {
  border-radius: 50%;
  background-color: transparent;
  border: solid 3px transparent;
  padding: 3px;
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #B5DFE9 50%,#E88B19 50%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.double-border--principal img {
  border-radius: 50%;
  width: 30px;
  box-shadow: 0 0 0 3px #27466C;
}

/* hover profile show menu */
.user-comp_profile:hover .user-comp_menu {
  display: block;
}

/* ICON APP */
.header__icon-APP {
  padding-right: 0.5em;
}

.header__icon-APP img {
  width: 40px;
  margin-top: -5px;
  padding-right: 0.25em;
}

.wrapper-header .header__icon-APP {
  padding-right: 0;
  padding-left: 0.7em;
}

.wrapper-header .header__icon-APP img {
  padding-right: 0;
}


/* MQ 750px */

@media screen and (max-width: 750px) {
  .top-bar-menu {
      margin-right: 75px;
      font-size: 14px;
  }
}

/* MQ 500px */

@media screen and (max-width: 500px) {
  .top-bar-menu span {display: none;}
  .top-bar-menu { margin-right: 70px; }
  .header__icon-APP img { margin-top: -1px; }
}




/**
 * Created by c3om on 3/29/21.
 */
.header__icon-APP.pr-1 {
    padding-right: 1em;
}

 .user-comp_profile-wrapper {
    position: relative;
    top: 0;
}


.main-header {
    padding: 0.2em 1em;
}

.top-bar-menu {
    margin-right: 0 /*230px*/;
}

.user-comp_profile-wrapper {
    width: auto /*225px*/;
}

.user-comp_menu {
    top: 60px;
}

.double-border--principal {
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #6AD8D2 50%,#E88B19 50%);
}

.user-comp_info-user .double-border img {
    width: 87px;
    background: #FFF;
}

.user-comp_info-user .double-border {
    width: 100px;
    height: 100px;
    margin-bottom: 1em;
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #6AD8D2 50%,#E88B19 50%);
}

.user-comp_info-user h1 {
    font-weight: bold;
}

ul.user-comp_submenu {
    list-style: none;
}

ul.user-comp_submenu .nav-item svg {
    margin-right: 10px;
    color: #27466c
}

ul.user-comp_submenu .nav-item a:hover svg {
    color: #838383;
}

.help svg {
    margin-right: 5px;
}

.help-title {
    font-weight: 300;
}

.user-comp_submenu li {
    display: block;
}

.user-comp_menu {
    margin: -2px 0 0 -140px !important;
}

@media screen and (max-width: 750px) {
    .help {
        margin-right: 5px;
        font-size: 20px;
    }

    .top-bar-menu {
        margin-right: 0;
        font-size: 14px;
    }

    .user-comp_menu {
        margin: -2px 0 0 -118px !important;
    }
}
@media screen and (max-width: 500px) {
    .user-comp_profile-wrapper {
        width: auto /*225px*/ !important;
        right: 0 !important;
    }

    .user-comp_menu {
        margin: -2px 0 0 -145px !important;
    }
}

.user-comp_menu {
  clear: both;
  margin: 0 0 0 -80px;
  width: 300px;
  background: url(/static/media/UserComp-bg.2ffd9c3b.jpg) no-repeat left top;
  background-color: #FFF;
  cursor: default;
  padding: 0.5em;
  text-align: left;
  z-index: 100;
  position: fixed;
  display: inline-block;
box-shadow: 0px 0px 14px 0px rgba(12,10,61,0.1);
}

.user-comp_menu ul {
  padding: 0;
}

ul.user-comp_submenu {
  color: #231F20;
  font-size: 20px;
  padding: 1em;
  font-weight: bold;
}

.user-comp_submenu li {
  margin-bottom: 1em;
}

.user-comp_submenu li, .user-comp_submenu li img {
  vertical-align: middle;
}

.user-comp_submenu li a {
  color: #231F20;
  text-decoration: none;
}

.user-comp_submenu li .fas {
  margin-right: 10px;
  color: #27466C;
}

.user-comp_submenu li a:hover, .user-comp_submenu li a:hover .fas {
  color: #838383;
  cursor: pointer;
}





.user-comp_info-user {
  width: 95%;
  text-align: center;
}

/* Tipography dropdown */

.user-comp_info-user h1 {
  font-size: 20px;
  line-height: 10px;
  margin-bottom: 0.5em;
}

.user-comp_info-user h2 {
  font-size: 16px;
  line-height: 10px;
  margin-bottom: 0.5em;
  font-weight: normal;
}

.user-comp_info-user h4 {
  font-size: 12px;
  font-weight: normal;
}


/* Avatar Inside */
.user-comp_info-user .double-border {
  border-radius: 50%;
  background-color: transparent;
  border: solid 4px transparent;
  padding: 3px;
  width: 100px;
  height: 100px;
  margin: 0.5em auto;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #B5DFE9 50%,#E88B19 50%);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.user-comp_info-user .double-border img {
  border-radius: 50%;
  width: 100px;
  box-shadow: 0 0 0 3px #FFF;
}


/* MQ 750px */

@media screen and (max-width: 750px) {
    .user-comp_profile-wrapper {
      width: 70px;
  }

  .user-comp_profile .user-name {
      display: none;
  }

  /* Avatar Principal */
  .double-border--principal {
      width: 30px;
      height: 30px;
  }

  .double-border--principal img {
      width: 30px;
  }

  /* dropdown */
  .user-comp_menu {
      margin: 0 0 0 -220px;
  }

  /* Avatar inside */
  .user-comp_info-user .double-border {
    width: 70px;
    height: 70px;
  }
  
  .user-comp_info-user .double-border img {
    width: 70px;
  }

  /* DropDown Container */
  .user-comp_menu {
    margin: 0 0 0 -136px;
    width: 200px;
  }

  ul.user-comp_submenu {
   font-size: 15px;
    padding: 1em;
  }
}

.wrapper-content {
  margin-left: 150px;
  margin-top: 75px;
}

/****************************/

.center-container {
  padding: 1em;
  padding-bottom: 75px; 
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
}


.container_two-col {
width: 48%;
}



/* MQ 920px */

@media screen and (max-width: 920px) {
  .container_two-col {
    width: 100%;
    margin-bottom: 1em;
}
}


/* MQ 750px */

@media screen and (max-width: 750px) {
  .wrapper-content {
      margin-left: 0;
  }

  .container_two-col {
    width: 100%;
  }

  .center-container {
    padding-bottom: 125px;
  }
}

/* MQ 500px */

@media screen and (max-width: 500px) {
  .center-container {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}

.wrapper-menu {
  width: 135px;
  background-color: #4E66C4;
  position: fixed;
  height: 100%;
  top: 58px;
  z-index: 2;
}

/****************************/

.navigation-menu {
  text-align: center;
  color: #FFF;
  font-size: 13px;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 12px;
}

.navigation-menu li {
  padding: 0.5em;
  display: block;
}

.navigation-menu li a {
  color: #FFF;
  text-decoration: none;
}

.navigation-menu li img {
  display: block;
  margin: auto;
  width: 30% /*before 34%*/;
  height: auto;
  vertical-align: top;
}

span.lesson-mark {display: none;}
.navigation-menu li span.lesson-mark img {display: none;}

.navigation-menu li.active span.lesson-mark img {
  float: right;
  margin-top: -45px;
  margin-right: -20px;
  width: 39px;
  /* display: block; */
  background-color: transparent;
}

.navigation-menu li a:hover img { 
  background-color: #567B91;
  border-radius: 6px;
  }
  
  .navigation-menu li.active img, .navigation-menu li.active:hover img {
    background-color: #27466C;
    border-radius: 6px;
  }
  
  .navigation-menu li.active a {
    color: #27466C;
    font-weight: bold;
    font-size: 12px;
  }

li.highlight-item a {
  color: #FFF700;
}

.nav-kinder img {
  width: 70px !important;
}

.scroll-bar.wrapper-menu {
  overflow: auto;
}

/* Style SCROLL BAR */
.scroll-bar.wrapper-menu .navigation-menu {
  height: 665px;
}

.navigation-menu, .wrapper-menu {
  scrollbar-width: thin;
  scrollbar-color: #6ba7cc #4f88ab;
}

.wrapper-menu::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #4f88ab
}

.wrapper-menu::-webkit-scrollbar {
	width: 6px;
	background-color: #4f88ab;
}

.wrapper-menu::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #6ba7cc;
}


/* MQ 750px */

@media screen and (max-width: 750px) {
  .wrapper-menu {
      width: 100%;
      height: 80px;
      bottom: 12px /* change for delete txt*/;
      top: auto;
  }

  .navigation-menu li img {
      width: 40px;
      height: 40px;
  }

  .navigation-menu {
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
        flex-grow: 1;
        align-items: stretch;
  }

  .navigation-menu li {
      width: 100%;
      text-align: center;
      padding: 0.25em 0 0.6em 0;
  }

  .navigation-menu li.active span.lesson-mark img {
      display: none;
  }

  .navigation-menu li.active {
    background-color: #27466C;
  }

  .navigation-menu li.active a {
    color: #FFF;
  }

  .navigation-menu .navigation-menu--item {
    display: none;
  }
  .scroll-bar.wrapper-menu { overflow: hidden; } 
}

/* MQ 500px */

@media screen and (max-width: 500px) {
  .wrapper-menu {
    height: 75px /* 78 change for delete txt*/;
  }

  .navigation-menu {
      font-size: 11px;
      letter-spacing: 0.3px;
  }

  .navigation-menu li.active a {
    font-size: 11px;
  }

  .navigation-menu li.active span.lesson-mark img {
      margin-top: 0;
  }
}

/* MQ 480px */

@media screen and (max-width: 480px) {
  .wrapper-menu {
    bottom: 15px /* 21 change for delete txt*/;
    }

  .navigation-menu .navigation-menu--item {
    display: none;
  }

  .navigation-menu li img {
    width: 35px;
  }
}


.wrapper-home-matter {
  padding: 1em;
  background-color: #FFF;
box-shadow: 0px 0px 14px 0px rgba(12,10,61,0.1);
  margin-bottom: 1.5em;
}

.wrapper-home-matter h1 {
  font-weight: bold;
  font-size: 20px;
  margin: 0;
  border-bottom: 1px solid #DDD;
  padding-bottom: 0.5em;
}



/******/

.content-item-matter {
  margin-top: 0.5em;
  display: flex;
flex-wrap: wrap;
    align-items: stretch;
}
.home-matter-select {
  background-color: #F1F1F1;
}

/* MQ 750px */

@media screen and (max-width: 750px) {
      
}
.item-matter--five-col {
  width: 18%;
  padding: 0.25em
}

.item-matter--five-col a {
  text-decoration: none;
  cursor: pointer;
}

.item-matter {
  background-color: #FFF;
  text-align: center;
  border-radius: 15px;
  padding: 0.25em;  
}

.item-matter:hover {
  background-color: #D9E8F2
}

.item-matter .item-active {
  background-color: #D9E8F2;
  border-radius: 15px;
  padding: 0.25em;
}

.item-matter img {
  width: 100%;
}

.item-matter-title-lesson {
    font-size: 12px;
    color: #222;
    font-weight: bold;
    margin: 0.25em 0 0 0
}




/* MQ 450px */

@media screen and (max-width: 450px) {
  .item-matter--five-col {
    width: 30%;
  }
}
.wrapper-home-task {
  padding: 1em;
  background-color: #E8E8E8;
box-shadow: 0px 0px 14px 0px rgba(12,10,61,0.1);
}

.wrapper-home-task h2 {
  color: #E88B18;
  font-size: 20px;
  margin: 0 0 0.5em 0;
}


/******/

.content-item-homework {
  border-bottom: 1px solid #DDD;
  padding: 1em 0.5em;
  color: #222;
  background-color: #FFF;
  font-size: 15px;
  display: flex;
flex-wrap: wrap;
    align-items: center;
  justify-content: space-between;
}

.wrapper-home-task .content-item-homework {
  background-color: #E8E8E8;
  padding: 0.5em 0.5em;
}

.item-homework--button {
  text-align: right;
}

.item-homework--homework {
  width: 70%;
}

.homework-description, .homework-icon-matter {
  display: inline-block;
  vertical-align: middle;
}

.homework-icon-matter {
  font-size: 40px;
  margin-right: 0.25em;
}

.txt-homework {
  color: #222;
  font-size: 14px;
  margin: 0 0 0.25em 0;
}

.date-homework {
  color: #9098AC;
  font-size: 13px;
  margin: 0.25em 0 0 0;
}

.matter-homework {  
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #9098AC;
}

/* button */

.button-homework {
    font-weight: bold;
    display: block;
    border-radius: 50px;
    padding: 1em 1.5em;
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    margin: 0.5em auto;
    background-color: #F2AF2B;
    border: 1px solid #F2AF2B;
    color: #FFF;
    width: 100%;
    cursor: pointer;
}

.button-homework:hover {
    background-color: #b3bcbf;
    border: 1px solid #b3bcbf;
}






/* MQ 750px */

@media screen and (max-width: 750px) {
      
}


.wrapper-home-homework {
  padding: 1em;
  background-color: #FFF;
box-shadow: 0px 0px 14px 0px rgba(12,10,61,0.1);
}

.wrapper-home-homework h2 {
  color: #2A426D;
  font-size: 20px;
  margin: 0 0 0.5em 0;
}


/******/

.content-item-homework {
  border-bottom: 1px solid #DDD;
  padding: 1em 0.5em ;
  color: #222;
  background-color: #FFF;
  font-size: 15px;
  display: flex;
flex-wrap: wrap;
    align-items: center;
  justify-content: space-between;
}

.item-homework--button {
  text-align: right;
}

.item-homework--homework {
  width: 70%;
}

.homework-description, .homework-icon-matter {
  display: inline-block;
  vertical-align: top;
}

.homework-icon-matter {
  font-size: 40px;
  margin-right: 0.25em;
}



.txt-homework {
  color: #222;
  font-size: 14px;
  margin: 0 0 0.25em 0;
  font-weight: bold;
}



.matter-homework {  
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #9098AC;
  margin: 0.25em 0;
  font-weight: bold;
}

/* button */

.button-homework {
    font-weight: bold;
    display: block;
    border-radius: 50px;
    padding: 1em 1.5em;
    font-size: 10px;
    text-transform: uppercase;
    text-align: center;
    margin: 0.5em auto;
    background-color: #F2AF2B;
    border: 1px solid #F2AF2B;
    color: #FFF;
    width: 100%;
    cursor: pointer;
}

.button-homework:hover {
    background-color: #b3bcbf;
    border: 1px solid #b3bcbf;
}

.button-see-more {
  color: #27466C;
  padding: 0;
  background-color: transparent;
  border: 0;
  margin: 1.5em 0;
  font-weight: bold;
  font-size: 13px;
  text-align: right;
  width: 100%;
}

.button-see-more:hover {
  color: #5A93B6;
}


.homework-div {
  display: flex;
  flex-wrap: wrap;
  color: #222;
  font-size: 12px;
  line-height: 0.5rem;
  grid-gap: 0 .5rem ;
  gap: 0 .5rem ;
}

.homework-date-div {
  background-color: #F7F7F7;
  width: 100%;
  margin:  .5rem 0;
  padding: .3rem;
}

.homework-date-title-test {
  font-weight: bold;
  margin-right: 0.2rem;
}

.date-homework-title {
  color: #222;
  font-size: 13px;
  margin: 0.25em 0 0 0;
  line-height: 0.5rem;
}


.date-homework {
  color: #222;
  font-size: 12px;
  margin: 0.25em 0 0 0;
}


.date-over-due {
  color: #B92A24;
}

.cursor-pointer {
  cursor: pointer;
}


/* MQ 750px */

@media screen and (max-width: 750px) {
      
}


.total-wrapper_lesson-progress {
  background-color: #F7F7F7;
  padding: 1.5em 1.5em 5em 1.5em;
}

.total-wrapper_lesson-progress h3 {
  font-weight: 800;
  font-size: 16px;
  margin: 0 0 0.25em 0;
}

.total-wrapper_lesson-progress h4 {
  font-weight: 600;
  font-size: 11px;
  margin: 0 0 2em 0;
}

/* TO MAKE SCROLL ON LESSON, ONLY LESSON */
.total-wrapper_lesson-progress .wrapper-lessons-progress {
  height: 450px;
  overflow-y: scroll;
}

.wrapper-lessons-progress::-webkit-scrollbar-track {	  
	border-radius: 10px;
  background-color: #FFF;
  border-left: 1px solid #E6E6E6;
  -moz-appearance: none !important;
}
  
.wrapper-lessons-progress::-webkit-scrollbar {
	width: 10px;
  background-color: #FFF;
  -moz-appearance: none !important;
}
  
.wrapper-lessons-progress::-webkit-scrollbar-thumb {
	border-radius: 10px;	 
  background-color: #D4D5D6;
  -moz-appearance: none !important;
}

@media screen and (max-width: 450px) {
      
  .total-wrapper_lesson-progress {
    padding: 1.5em 0.5em 5em 0.5em;
  }
}
.wrapper-lessons-progress {
  border-radius: 15px;
box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.2);  
}

/******/

.container-items-lesson a {
  border-bottom: 1px solid #DDD;
  padding: 1em ;
  text-decoration: none;
  color: #222;
  cursor: pointer;
  background-color: #FFF;
  font-size: 15px;
  display: flex;
  cursor: pointer;
    align-items: center;
    justify-content: space-between;
}

.container-items-lesson a:hover {
  background-color: #D9E8F2;
box-shadow: inset -1px -1px 13px -1px rgba(184,202,214,1);
}

.container-items-lesson:first-child a {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.container-items-lesson:last-child a {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border: 0;
}


.col-left--items-lesson {
  width: 80%;
}

.col-right--items-lesson {
  width: 100px;
  text-align: center;
}


.progress {
  padding: 0;
  width: 150px;
  height: 5px;
  overflow: hidden;
  background: #FFF;
  border-radius: 26px;
  border: 1px solid #DDD;
}

.bar {
  position: relative;
  float: left;
  min-width: 1%;
  height: 100%;
  background: #B0D8B9;
}

.icon-finished-lesson {
  font-size: 20px;
  color: #B0D8B9;
}

/* COntendor */

@media screen and (max-width: 450px) {
      
  .col-right--items-lesson {
   width: 30px;
  }
  .container-items-lesson a {
    font-size: 14px;
  }
}
.wrapper-footer {
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 2;
}

/****************************/


.main-footer-students {
  padding: 1em ;
  text-decoration: none;
  color: #FFF;
  background-color: #2C3140;
  font-size: 13px;
  display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-languages_languages-students {
  margin: 0;
  padding: 0;
}

.footer-languages_languages-students li {
    display: inline-block;
    padding: 0 0.25em;
    vertical-align: middle;
}


.footer-languages_languages-students li a {
    color: #FFF;
    text-decoration: underline;
    cursor: pointer;
}

.footer-languages_languages-students li a:hover {
    text-decoration: none;
}

.col-right--copyright .red-link {
  color: #EF1A1A
}

.col-right--copyright i {
  display: none;
}

.col-right--copyright a {
  cursor: pointer;
}



/* MQ 750px */

@media screen and (max-width: 750px) {
  .col-right--copyright, .col-left--footer-laguages {
      font-size: 12px;
  }


}

/* MQ 500px */

@media screen and (max-width: 500px) {
  .footer-languages_languages-students span, .footer-languages_languages-students .fas { display: none;}
  .footer-languages_languages-students { text-transform: uppercase;}
}

/* MQ 480px */

@media screen and (max-width: 480px) {
  .col-right--copyright .txt-copyright {
    display: none;
  }

  .col-right--copyright i {
    display: inline-block;
    padding: 0 0.5em;
  }
}

.wrapper-content {
  margin-left: 150px;
  margin-top: 75px;
}

/****************************/

.center-container {
  padding: 1em;
  padding-bottom: 75px; 
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
}

.container_two-col {
width: 48%;
}



/* MQ 920px */

@media screen and (max-width: 920px) {
  .container_two-col {
    width: 100%;
    margin-bottom: 1em;
}
}


/* MQ 750px */

@media screen and (max-width: 750px) {
  .wrapper-content {
      margin-left: 0;
  }

  .container_two-col {
    width: 100%;
  }

  .center-container {
    padding-bottom: 125px;
  }
}

/* MQ 500px */

@media screen and (max-width: 500px) {
  .center-container {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}


/* Special styles for lesson tabs */
.wrapper-lesson-tabs {
  padding: 1em;
  padding-bottom: 75px; 
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
}

.content-max-width {
  max-width: 100% !important;
}

.wrapper-lesson-tabs .wrapper-lessons .title-lesson {
  font-size: 17px;
}

.wrapper-lesson-tabs .wrapper-lessons .col-left img {
  width: 55px;
  margin-left: 0.5em;
}

.wrapper-lesson-tabs .wrapper-lessons .lesson-grade {
    font-size: 25px;
}

.wrapper-lesson-tabs .wrapper-lessons .col-right svg {
  left: -26px;
}
.total-wrapper_lessons {
  width: 100%;
}

.total-wrapper_lessons h2 {
  font-weight: 800;
  font-size: 18px;
  margin: 0 0 0.5em 0;
  border-bottom: 1px solid #DDD;
  padding-bottom: 0.5em;
}

.module-lesson-title {
  border-bottom: 1px solid #DDD;
  padding: 0.5em;
  text-decoration: none;
  color: #222;
  background-color: #FFF;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 1em;
  display: flex;
    align-items: center;
    justify-content: space-between;
}


.col-left--module-lesson-title {
  width: 60%;
}

.col-right--module-lesson-title {
  width: 125px;
  text-align: center;
}

.selectTrelloGrade .css-g1d714-ValueContainer {
  cursor: pointer;
}

.selectTrelloGrade .css-yk16xz-control {
	border: 0;
	background: transparent;
	font-size: 13px;
  border-radius: 0;
  font-family: 'Roboto' , Arial , Helvetica , sans-serif;
}

.selectTrelloGrade .css-1pahdxg-control {
	border: 0;
	background: transparent;
	font-size: 13px;
  font-family: 'Roboto' , Arial , Helvetica , sans-serif;
	border-radius: 0;
	box-shadow:0 0 0 0px #D3D5DB;
} 

.selectTrelloGrade .css-1okebmr-indicatorSeparator {
	display: none;
}

.selectTrelloGrade .css-1rhbuit-multiValue {
    background-color: transparent;
    font-weight: bold;
}

.selectTrelloGrade .css-xb97g8:hover {
    background-color: transparent;
    color: #2298BD;
}

.selectTrelloGrade .css-1wa3eu0-placeholder {
  color: #222;
}

/* MQ 480px */

@media screen and (max-width: 480px) {
  .selectTrelloGrade .css-2b097c-container {
    width: 70%;
    margin: auto;
  }

  .col-right--module-lesson-title .selectTrelloGrade .css-2b097c-container {
    width: 100%;
  }
}
.wrapper-lessons .total-wrapper_lessons {
  width: 100%;
}

.wrapper-lessons .total-wrapper_lessons h2 {
  font-weight: 800;
  font-size: 18px;
  margin: 0 0 0.5em 0;
  border-bottom: 1px solid #DDD;
  padding-bottom: 0.5em;
}

/******/

.wrapper-lessons a {
  background-color: #FFF;
  border: 3px solid #D9E8F2;
  border-radius: 15px;
  margin-bottom: 0.25em;
  cursor: pointer;
  display: flex;
    align-items: stretch;
    justify-content: space-between;
  position: relative;
}

.wrapper-lessons a {
  text-decoration: none;
}

.wrapper-lessons a:hover {
  background-color: #D9E8F2;
}

.wrapper-lessons .item-active {
  background-color: #D9E8F2;
}

.wrapper-lessons .col-left {
    width: 80%;
}

.wrapper-lessons .col-right {
  position: relative;
  display: flex;
  align-items: center;
  /* background cambiante */
  background-color: #4662E5;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
}

.wrapper-lessons .arrow-right {fill: #4662E5;}

.wrapper-lessons .title-lesson {
  font-size: 25px;
  color: #222;
  font-weight: bold;
  margin-left: 0.25em;
}

.wrapper-lessons .lesson-grade {
  color: #FFF;
  font-size: 30px;
  padding: 0 0.5em 0 0.25em;
  font-weight: bold
}

.wrapper-lessons .col-left img, .wrapper-lessons .col-left span {
    vertical-align: middle;
}

.wrapper-lessons .col-left img {
  width: 60px;
  margin-left: 0.8em;
  padding: 0.25em 0;
}

.wrapper-lessons .col-right svg { 
  vertical-align: middle;
  display: inline-block;
  position: absolute;
  height: 100%;
  left: -29px
}

.wrapper-lessons .col-right span {
 margin: auto

  
}

/* MQ 750px */

@media screen and (max-width: 750px) {
  .wrapper-lessons .title-lesson { font-size: 20px; }

  .wrapper-lessons .col-left img {  width: 60px; }

  .wrapper-lessons .lesson-grade { font-size: 30px; }

  .wrapper-lessons .col-right svg { left: -26px }
}

/* MQ 500px */

@media screen and (max-width: 500px) {
  .wrapper-lessons .title-lesson { font-size: 16px; }

  .wrapper-lessons .col-left img {  width: 40px; }

  .wrapper-lessons .lesson-grade { font-size: 20px; }

  .wrapper-lessons .col-right svg { left: -20px }
}

/* MQ 450px */

@media screen and (max-width: 500px) {
  .wrapper-lessons .title-lesson { font-size: 15px; }
  .wrapper-lessons .col-left img { margin-left: 0.4em; }
}


.wrapper-table-homework-task {
  width: 100%;
  /* max-width: 850px; */
  padding: 0;
}

.wrapper-table-homework-task h1 {
  font-weight: 800;
  font-size: 20px;
  margin: 0 0 1em 0;
  border-bottom: 1px solid #DDD;
  padding-bottom: 0.5em;
}

/******/

table.content-table-homework {
  border-collapse: collapse;
  width: 100%;
  margin: 2em 0;
}

.content-table-homework tr/*:nth-of-type(odd)*/ {
  text-align: left;
  vertical-align: top;
  color: #2A426D;
  font-size: 13px;
}

.content-table-homework tr {
  border-bottom: 1px solid #EBEBEB;
}

.content-table-homework thead tr {
  border-bottom: 0;
}

.content-table-homework th {
  padding-bottom: 1em;
}

.content-table-homework td {
  padding: 1em 0;
  font-size: 16px;
}

.content-table-homework.content-table-progress td,
.content-table-homework.content-table-progress th {
  padding: 10px;
}

.content-table-homework.content-table-progress th {
  border-bottom: 1px solid #EFEFEF;
}

.content-table-homework .table-homework--txt-matter {
  font-size: 11px;
  color: #9098AC;
  margin: 0.25em 0;
  font-weight: bold;
  text-transform: uppercase;
}

.content-table-homework .table-homework--title {
  font-weight: bold;
  font-size: 16px;
  color: #000;
}

.content-table-homework .table-homework--date {
  color: #222;
  font-size: 12px;
}

.content-table-homework .table-homework--name-matter {
  font-size: 14px;
  color: #000;
}

/* button */

.button-table-homework {
    font-weight: bold;
    display: block;
    border-radius: 50px;
    padding: 0.8em;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto;
    background-color: #F2AF2B;
    border: 1px solid #F2AF2B;
    color: #FFF;
    width: 100%;
    cursor: pointer;
}

.button-table-homework:hover {
    background-color: #b3bcbf;
    border: 1px solid #b3bcbf;
}



/* MQ 750 */


@media screen and (max-width: 750px) {
  table.content-table-homework, .content-table-homework thead, 
  .content-table-homework tbody, 
  .content-table-homework th, 
  .content-table-homework td, 
  .content-table-homework tr { 
    display: block; 
  }

  .content-table-homework thead tr { 
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .content-table-homework tr { border-bottom: 8px solid #FFF; margin-bottom: 1em; }

  .content-table-homework td { 
    border: none;
    border-bottom: 1px solid #EBEBEB; 
    position: relative;
    padding-left: 50%;
    font-size: 13px; 
  }

  .content-table-homework td:before { 
    position: absolute;
    top: 15px;
    left: 6px;
    width: 45%; 
    padding-right: 10px; 
    white-space: nowrap;
    content: attr(data-column);
    color: #2A426D;
    font-weight: bold;
  }

  .content-table-homework tr {
    background-color: #EFEFEF;
  }

  .button-table-homework {
    width: auto;
    padding: 0.8em 2em;
    margin: 0;
  }
}


/* MQ 500 */

@media screen and (max-width: 500px) { 
  .content-table-homework td {  font-size: 12px; }
}

@media screen and (max-width: 750px) { 
  .wrapper-table-homework {
      margin: auto;
  }
}

/* MQ 450 */

@media screen and (max-width: 450px) { 
  .content-table-homework td {  font-size: 10px; }
  .content-table-homework .table-homework--title { font-size: 14px; }
}


/* TABS MESSAGE */
.wrapper-message-tabs {
  width: 100%;
  max-width: 1000px;
  margin: 0;
  padding: 0 1.5em 0.5em 1.5em;
}

.message-tabs {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.message-tabs input[type=radio]:checked ~ label{
  color: #DADADA;
  font-weight: bold;
  transition: color ease 0.2s;
}

.message-tabs label {
  color: #DADADA;
  padding: 0.5rem 0;
  cursor: pointer;
  font-weight: bold;
  width: 50%;
  text-align: center;
  border-bottom: 3px solid #DADADA;
  font-size: 14px;
}

.message-tabs label .fas {
  font-size: 20px;
  padding: 0 0.5em;
}

.number-no-read {
  font-weight: normal;
  font-style: italic;
}

.message-tabs .message-tabs--content {
  order: 99;
  flex-grow: 1;
  width: 100%;
  display: none;
  padding: 1rem 1em 3em 1em;  
  -webkit-animation: scale 0.7s ease-in-out;
    animation: scale 0.7s ease-in-out;    
}

@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 0;
    }
  50% {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    opacity: 0.5;
    }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 0;
    }
  50% {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    opacity: 0.5;
    }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

.message-tabs input[type="radio"] {
  display: none;
} 
.message-tabs input[type="radio"]:checked + label {
  color: #e88b18;
  border-bottom: 3px solid #e88b18;
  background-color: #ffeece;
/* color: #5A93B6;
border-bottom: 3px solid #5A93B6; */
}

.message-tabs input[type="radio"]:checked + label + .message-tabs--content {
  display: block;
} 

.message-tabs input[type="radio"]:checked + .tab-homework {
  background-color: #c8dce8;
  border-bottom: 3px solid #5a93b6;
  color: #27466c;
}

@media screen and (max-width: 750px) { 
  .wrapper-message-tabs {
    margin: auto 0;
    padding: 0;
  }
}

@media screen and (max-width: 450px) { 
  .message-tabs .message-tabs--content {
    padding: 1rem 0.25em 3em 0.25em; 
  }
}

.wrapper-table-homework-task {
  width: 100%;
  /* max-width: 850px; */
  padding: 0;
}

.wrapper-table-homework-task h1 {
  font-weight: 800;
  font-size: 20px;
  margin: 0 0 1em 0;
  border-bottom: 1px solid #DDD;
  padding-bottom: 0.5em;
}

/******/

table.content-table-homework {
  border-collapse: collapse;
  width: 100%;
  margin: 2em 0;
}

.content-table-homework tr/*:nth-of-type(odd)*/ {
  text-align: left;
  vertical-align: top;
  color: #2A426D;
  font-size: 13px;
}

.content-table-homework tr {
  border-bottom: 1px solid #EBEBEB;
}

.content-table-homework thead tr {
  border-bottom: 0;
}

.content-table-homework th {
  padding-bottom: 1em;
}

.content-table-homework td {
  padding: 1em 0;
  font-size: 16px;
}

.content-table-homework .table-homework--txt-matter {
  font-size: 11px;
  color: #9098AC;
  margin: 0.25em 0;
  font-weight: bold;
  text-transform: uppercase;
}

.content-table-homework .table-homework--title {
  font-weight: bold;
  font-size: 16px;
  color: #000;
}

.content-table-homework .table-homework--date {
  color: #222;
  font-size: 12px;
}

.content-table-homework .table-homework--name-matter {
  font-size: 14px;
  color: #000;
}

/* button */

.button-table-homework {
    font-weight: bold;
    display: block;
    border-radius: 50px;
    padding: 0.8em;
    font-size: 12px;
    text-transform: uppercase;
    text-align: center;
    margin: 0 auto;
    background-color: #F2AF2B;
    border: 1px solid #F2AF2B;
    color: #FFF;
    width: 100%;
    cursor: pointer;
}

.button-table-homework:hover {
    background-color: #b3bcbf;
    border: 1px solid #b3bcbf;
}



/* MQ 750 */


@media screen and (max-width: 750px) {
  table.content-table-homework, .content-table-homework thead, 
  .content-table-homework tbody, 
  .content-table-homework th, 
  .content-table-homework td, 
  .content-table-homework tr { 
    display: block; 
  }

  .content-table-homework thead tr { 
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .content-table-homework tr { border-bottom: 8px solid #FFF; margin-bottom: 1em; }
  .content-table-homework.content-table-progress tr { border-bottom: 8px solid #FFF; margin-bottom: 0; }

  .content-table-homework td { 
    border: none;
    border-bottom: 1px solid #EBEBEB; 
    position: relative;
    padding-left: 50%;
    font-size: 13px; 
  }

  .content-table-homework td:before { 
    position: absolute;
    top: 15px;
    left: 6px;
    width: 45%; 
    padding-right: 10px; 
    white-space: nowrap;
    content: attr(data-column);
    color: #2A426D;
    font-weight: bold;
  }

  .content-table-homework tr {
    background-color: #EFEFEF;
  }

  .button-table-homework {
    width: auto;
    padding: 0.8em 2em;
    margin: 0;
  }
}


/* MQ 500 */

@media screen and (max-width: 500px) { 
  .content-table-homework td {  font-size: 12px; }
}

@media screen and (max-width: 750px) { 
  .wrapper-table-homework {
      margin: auto;
  }
}

/* MQ 450 */

@media screen and (max-width: 450px) { 
  .content-table-homework td {  font-size: 10px; }
  .content-table-homework .table-homework--title { font-size: 14px; }
  .button-table-homework { font-size: 11px; }
}

/* STYLE TASK SEARCH */
.space-between {
  justify-content: space-between;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    align-items: center;

    padding-bottom: 7px !important;
    border-bottom: 1px solid #EAEAEA;
}

.wrapper-col.space-between .three-col {
  width: 32%;
}

.wrapper-col.space-between .three-col .selectTrelloGrade {
  background-color: #EFEFEF;
}

.wrapper-col.space-between .txt-select {
  font-size: 12px;
}

@media screen and (max-width: 500px) {
  .wrapper-col.space-between .three-col:nth-child(1) {
    width: 100%;
    padding-bottom: 10px;
  }

  .wrapper-col.space-between .three-col:nth-child(2),
  .wrapper-col.space-between .three-col:nth-child(3) {
    width: 49%;
  }
}


@media screen and (max-width: 420px) {
  .wrapper-col.space-between .three-col:nth-child(2),
  .wrapper-col.space-between .three-col:nth-child(3) {
    width: 100%;
    padding-bottom: 5px;
  }
}

.comments-modal-title {
    margin: 0;
    margin-top: -15px;
}

.modal-comments-body {
    padding: 35px 0
}

.modal-comments-body .txt-no-comments {
    text-align: center;
    color: #CCC;
}

.modal-comments-body .txt-no-comments i {
    display: block;
    margin: 0 auto 0.5em auto;
    font-size: 30px;
}



.wrapper-library {
  width: 100%;
}

/*****************/

.content-list-library {
  width: 100%;
  max-width: 1000px;
  margin: 2em 0;
}

.wrapper-col {
padding: 0.25em; 
      display: flex;
      flex-wrap: wrap;
}

.two-col {
width: 48%;
}

.four-col {
  width: 24%;
}

.general-module {
  padding: 0.5em;
  margin: 0.25em;
}

img.image-library {
  width: 100%;
  height: 260px;
  /*max-height: 260px;  review*/
  object-fit: cover;
  margin-bottom: 0.5em;
  border: 1px solid #DDD;
}

.list-library__aditional {
  color: #9098ac;
  font-size: 12px;
  margin: .25em 0 0;
}

.list-library__title, .list-library__title a {
  color: #27466C;
  font-size: 14px;
  margin: 0 0 .25em;
  font-weight: 700;
}

.list-library__title a:hover {
  color: #5A93B6;
}

.list-library__description {
  font-size: 14px;
  margin: 0.5em 0;
  color: #222;
}

.general-module .selectTrelloGrade {
  background-color: #EFEFEF;
}


.content-list-library h3 {
  font-size: 14px;
  color: #2A426D;
  margin: 0 1em;
}

.wrapper-library h2 {
  border-bottom: 1px solid #D8D8D8;
  padding-bottom: 0.3em;
}

/* MQ 750px */

@media screen and (max-width: 750px) {
  .four-col {  width: 32%; }  
}

/* MQ 600px */

@media screen and (max-width: 600px) {
  .four-col {  width: 48%; }  
}

/* MQ 450px */

@media screen and (max-width: 450px) {
  .four-col {  width: 100%; }
  .content-list-library .two-col { width: 100%;}
}


.book div {
    background-color: #F7F7F7 !important;
    font-size: 15px !important;
    color: #222 !important;
    font-weight: bold !important;
    font-family: 'Roboto', sans-serif !important;
}

.btn-back {
    background-color: transparent;
	border: none;
	color: #2A426D;
    font-weight: bold;
    margin-bottom: 1em;
}

.btn-back:hover {
    color: #5A93B6;
}


.pdf div {    
    font-size: 15px !important;
    color: #222 !important;
    font-weight: bold !important;
    font-family: 'Roboto', sans-serif !important;
    text-align: center;
}
.pageControls{
    display: flex;
    flex: 1 1;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 20px;
    width: 50%;
    margin-top: 20px;
    margin-right: 30%;
    margin-left: 30%;
  }
.book-title{
    justify-content: center;
    display: flex;
    width: 100%;
    text-align: center;
}
.pdfContainer{
    display: flex;
    justify-content: center;
    width: 100%;
}
.downloadPdfLink{
    color: white !important;
}
.downloadPdfLink:hover{
    color:white !important;
}
.downloadPdfButton{
    float: right;
}
.btn-primary{
    background-color: #27466c!important;
    border: 1px solid #27466c!important;
    color:white;
    border-radius: 30%;
    width: 45px;
    height: 45px;
    font-size: 20px;
  }
/* TABS MESSAGE */
.wrapper-message-tabs {
  width: 100%;
  max-width: 1000px;
  margin: 0;
  padding: 0 1.5em 0.5em 1.5em;
}

.message-tabs {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.message-tabs input[type=radio]:checked ~ label{
  color: #DADADA;
  font-weight: bold;
  transition: color ease 0.2s;
}

.message-tabs label {
  color: #DADADA;
  padding: 0.5rem 0;
  cursor: pointer;
  font-weight: bold;
  width: 50%;
  text-align: center;
  border-bottom: 3px solid #DADADA;
}

.message-tabs label .fas {
  font-size: 20px;
  padding: 0 0.5em;
}

.number-no-read {
  font-weight: normal;
  font-style: italic;
}

.message-tabs .message-tabs--content {
  order: 99;
  flex-grow: 1;
  width: 100%;
  display: none;
  padding: 1rem 1em 3em 1em;  
  -webkit-animation: scale 0.7s ease-in-out;
    animation: scale 0.7s ease-in-out;    
}

@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 0;
    }
  50% {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    opacity: 0.5;
    }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale {
  0% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    opacity: 0;
    }
  50% {
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
    opacity: 0.5;
    }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

.message-tabs input[type="radio"] {
  display: none;
} 
.message-tabs input[type="radio"]:checked + label {
    color: #e88b18;
    border-bottom: 3px solid #e88b18;
    background-color: #ffeece;
  /* color: #5A93B6;
  border-bottom: 3px solid #5A93B6; */
}
.message-tabs input[type="radio"]:checked + label + .message-tabs--content {
  display: block;
} 

@media screen and (max-width: 750px) { 
  .wrapper-message-tabs {
    padding: 0;
  }
}

@media screen and (max-width: 450px) { 
  .message-tabs .message-tabs--content {
    padding: 1rem 0.25em 3em 0.25em; 
  }

  .message-tabs label { font-size: 12px;}
}
/* TABLE */

table.table-message {
  width: 100%;
  border-collapse: collapse;
  background-color: #F4F4F4;
}

.table-message td {
  white-space: nowrap;
  padding: 1.5rem .75rem;
    vertical-align: top;
    border-bottom: 1px solid #dee2e6;
    vertical-align: middle;
    cursor: pointer;
}

table.table-message tr:hover {
  /* -webkit-box-shadow: 0px 6px 12px 0px rgba(12,10,61,0.1);
-moz-box-shadow: 0px 6px 12px 0px rgba(12,10,61,0.1);
  box-shadow: 0px 6px 12px 0px rgba(12,10,61,0.1); */

  /* -webkit-box-shadow: inset 0px -25px 14px -21px rgba(0,0,0,0.1);
-moz-box-shadow: inset 0px -25px 14px -21px rgba(0,0,0,0.1);
box-shadow: inset 0px -25px 14px -21px rgba(0,0,0,0.1); */
box-shadow: 0 8px 13px -3px rgba(0,0,0,0.1);
border-bottom: 2px solid #FFF;
} 



.td-truncate {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 50px;
}

.message-tabs .message-date {
  color: #9098AC;
  font-size: 12px;
}

.button-message-tabs {
    font-weight: bold;
    display: block;
    border: 0;
    background-color: transparent;
    font-size: 11px;
    text-transform: uppercase;
    color: #F2AF2B;
    cursor: pointer;
}

/* .button-message-tabs:hover {
  color: #27466C;
} */

.button-message-tabs .fas {
  font-size: 22px;
  padding: 0 0.25em
}

.button-message-tabs p {
  margin: 0.25em;
}

/* BUTTONS PRINCIPAL */

.message-content-buttons-mail {
  text-align: right;
  width: 100%;
  padding-bottom: 1em;
  border-bottom: 2px solid #F1F1F1;
    /* -webkit-box-shadow: 0px 9px 5px -5px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 9px 5px -5px rgba(0,0,0,0.1);
  box-shadow: 0px 9px 5px -5px rgba(0,0,0,0.1); */
}

.message-content-buttons-mail button {
  margin: 0.5em;
}

.button-primary, .button-secondary {
  border-radius: 5px;
  padding: 0.8em 1.5em;
  font-size: 12px;
  text-transform: uppercase;
  color: #FFF;
  cursor: pointer;
}

.button-primary .fas, .button-secondary .fas {
  font-size: 14px;
  margin-right: 0.5em;
}

.button-primary {
  background-color: #F2AF2B;
  border: 1px solid #F2AF2B;
}

.button-secondary {
  background-color: #27466C;
  border: 1px solid #27466C;
}

.button-primary:hover {
  background-color: #B3BCDF;
  border: 1px solid #B3BCDF;
}

.button-secondary:hover {
  background-color: #5A93B6;
  border: 1px solid #5A93B6;
}

.button-primary:hover {
    background-color: #b3bcbf;
    border: 1px solid #b3bcbf;
}




/* MQ 500 */

@media screen and (max-width: 500px) { 
  .clear, .button-message-tabs p {display: none;}
  .message-tabs label .fas { font-size: 28px; }
  .number-no-read { display: block; padding-top: 0.5em; }
}


.container-modal-message {
  width: 90%;
  max-width: 850px;
  background-color: #FFF;
  margin: 2em auto;
  padding: 1em;
  box-shadow: 0 2px 7px 0 rgba(0,0,0,0.26);
  border-radius: 15px;
}

/****************************/

.modal-message-body {
  padding: 3em 1em 4em 1em; 
      display: flex;
      flex-wrap: wrap;
  align-items: center;

      justify-content: space-between;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
}


.modal-message-body--col-name {
width: 60%;
  font-weight: bold;
}

.modal-message-body--col-date {
  width: 40%;
  text-align: right;
  color: #9098AC;
  font-size: 14px;
}

.modal-message-body--col-message {
  width: 100%;
  border-top: 1px solid #DDD;
  padding-top: 1em;
  margin-top: 1em;
}

h1.title-modal-message {
  margin: 0;
  font-size: 22px;
  padding: 0 0.6em;
}

.text-align-right {
  text-align: right;
}

/* new button */
.button-more-answers {
  margin-top: 3em;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  color: #27466C;
  text-decoration: none;
  display: block;
  width: 100%;
}
.button-more-answers:hover {
  color: #5A93B6;
}


.button-more-answers .fas {
  font-size: 20px;
  margin-right: 0.25em;
  vertical-align: middle;
}


.button-modal-message-primary {
  border-radius: 50px;
  padding: 1em 1.5em;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  margin: 0.5em;
  background-color: #F2AF2B;
  border: 1px solid #F2AF2B;
  color: #FFF;
  cursor: pointer;
}

.button-modal-message-primary:hover {
  background-color: #b3bcbf;
  border: 1px solid #b3bcbf;
}

.button-modal-message-secondary {
  border-radius: 50px;
  padding: 1em 1.5em;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  margin: 0.5em;
  background-color: #FFF;
  border: 1px solid #2CAA57;
  color: #222;
  cursor: pointer;
}

.button-modal-message-secondary:hover {
  color: #b3bcbf;
  border: 1px solid #b3bcbf;
}


/* CONTENT RESPONSE */

.container-modal-message__content-response {
  background-color: #ECECEC;
  padding: 2em 1em 4em 1em;
}

.content-response-instruction {
  font-size: 12px;
  color: #9098AC;
}

.content-response-instruction p {
  text-align: left !important;
}

.content-response-instruction h2 {
  font-size: 12px;
  color: #9098AC;
}

/* .form-group-message {
  margin: 3em auto 1em auto;
  border-top: 1px solid #DADADA;
  padding-top: 1em;
} */

.container-modal-message__content-response .button-modal-message-primary {
  width: 150px
}

.form-group-message label {
  font-size: 14px;
}

.form-group-message textarea {
  width: 96%;
  font-family: 'Roboto' , Arial , Helvetica , sans-serif;
  padding: 0.8em;
  margin: 1em auto;
  background-color: transparent;
  color: #222;
  font-size: 14px;
  height: 100px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #E0E0E0;

  -webkit-appearance: none;
}

.form-group-message textarea::-webkit-input-placeholder {  
  color: #222; 
}

.form-group-message textarea:-moz-placeholder { 
  /* Firefox 18- */ color: #222;  
}

.form-group-message textarea::-moz-placeholder {  
  /* Firefox 19+ */   color: #222; 
}

.form-group-message textarea:-ms-input-placeholder {     
  color: #222;  
}

/* MQ 500px */

@media screen and (max-width: 500px) {
  .container-modal-message .modal-footer {
      text-align: left;
  }
}

@media screen and (max-width: 450px) {
  .modal-message-body--col-name {
    width: 100%;
  }

  .modal-message-body--col-date {
    width: 100%;
    text-align: left;
    margin-top: 0.5em;
  }

 .MuiDialog-paper { margin: 0 !important;}
 .MuiDialog-paperFullWidth {
    width: calc(100% - 25px) !important;
  }
  .MuiDialogContent-root {
    padding: 8px 8px !important;
  }

  .modal-message-body { padding: 2em 0.5em 4em 0.5em; font-size: 14px;;}
  h1.title-modal-message {padding: 0 0.25em; font-size: 15px;  }
  .modal-footer {padding: 0.5em;}
  .modal-message-body--col-date { font-size: 12px; }

  
}



/******/

.preview-docs-message {
  width: 100%;
  margin-top: 1.5em;
  display: flex;
flex-wrap: wrap;
    align-items: stretch;
        align-content: space-between
}

.preview-docs-message--seven-col {
  width: 12%;
  padding: 0.25em;
}

.preview-docs-message--seven-col img {
  width: 100%;
  height: 105px;
  object-fit: cover;
  border: 1px solid #DDD;
}

.preview-docs-message--seven-col p {
  font-size: 12px;
  white-space: nowrap; 
  width: 95%; 
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  margin: 0.25em 0;
  color: #525d79;
}

.preview-docs-message--seven-col p:hover {
  overflow: visible;
  white-space: nowrap;
}





/* MQ 750px */

@media screen and (max-width: 750px) {
  .preview-docs-message--seven-col {
    width: 20%;
    margin-bottom: 1em;
  }  
}

/* MQ 500px */

@media screen and (max-width: 500px) {
  .preview-docs-message--seven-col {
    width: 30%;
  }  
}


/* TABLE */

table.table-message {
  width: 100%;
  border-collapse: collapse;
  background-color: #F4F4F4;
}

.table-message td {
  white-space: nowrap;
  padding: 1.5rem .75rem;
    vertical-align: top;
    border-bottom: 1px solid #dee2e6;
    vertical-align: middle;
}

.td-truncate {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 50px;
}

.message-tabs .message-date {
  color: #9098AC;
  font-size: 12px;
}

.button-message-tabs {
    font-weight: bold;
    display: block;
    border: 0;
    background-color: transparent;
    font-size: 11px;
    text-transform: uppercase;
    color: #F2AF2B;
    cursor: pointer;
}

/* .button-message-tabs:hover {
  color: #27466C;
} */

.button-message-tabs .fas {
  font-size: 22px;
  padding: 0 0.25em
}

.button-message-tabs p {
  margin: 0.25em;
}

/* BUTTONS PRINCIPAL */

.message-content-buttons-mail {
  text-align: right;
  width: 100%;
  padding-bottom: 1em;
  border-bottom: 2px solid #F1F1F1;
    /* -webkit-box-shadow: 0px 9px 5px -5px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 9px 5px -5px rgba(0,0,0,0.1);
  box-shadow: 0px 9px 5px -5px rgba(0,0,0,0.1); */
}

.message-content-buttons-mail button {
  margin: 0.5em;
}

.button-primary, .button-secondary {
  border-radius: 5px;
  padding: 0.8em 1.5em;
  font-size: 12px;
  text-transform: uppercase;
  color: #FFF;
  cursor: pointer;
}

.button-primary .fas, .button-secondary .fas {
  font-size: 14px;
  margin-right: 0.5em;
}

.button-primary {
  background-color: #F2AF2B;
  border: 1px solid #F2AF2B;
}

.button-secondary {
  background-color: #27466C;
  border: 1px solid #27466C;
}

.button-primary:hover {
  background-color: #B3BCDF;
  border: 1px solid #B3BCDF;
}

.button-secondary:hover {
  background-color: #5A93B6;
  border: 1px solid #5A93B6;
}

.button-primary:hover {
    background-color: #b3bcbf;
    border: 1px solid #b3bcbf;
}
/* PAGINATION */

.MuiPagination-root {
  margin-top: 1.5em;
}

.MuiPagination-ul li button {
  margin: 0;
}

.MuiPagination-root  .MuiPaginationItem-root {
  border-radius: 2px;
  height: 24px;
  min-width: 28px;
}

.MuiPagination-root .MuiButtonBase-root {
  color: #999;
  font-size: 13px;
  font-weight: bold;
  padding: 0;
}

.MuiPagination-root .MuiButtonBase-root:hover {
  color: #5A93B6;
  background-color: transparent;
}

.MuiPagination-root .MuiPaginationItem-page.Mui-focusVisible,
.MuiPagination-root .MuiPaginationItem-page.Mui-selected,
.MuiPagination-root .MuiPaginationItem-page.Mui-selected:hover {
  background-color: #5A93B6;
  color: #FFF;
}




/* MQ 500 */

@media screen and (max-width: 500px) { 
  .clear, .button-message-tabs p {display: none;}
  .message-tabs label .fas { font-size: 28px; }
  .number-no-read { display: block; padding-top: 0.5em; }
}

@media screen and (max-width: 450px) { 
  .table-message .message-name {
    font-size: 14px;
    white-space: unset;
  }
}
.container-modal-message {
  width: 90%;
  max-width: 850px;
  background-color: #FFF;
  margin: 2em auto;
  padding: 1em;
  box-shadow: 0 2px 7px 0 rgba(0,0,0,0.26);
  border-radius: 15px;
}

/****************************/

.modal-message-body {
  padding: 3em 1em 4em 1em; 
      display: flex;
      flex-wrap: wrap;
  align-items: center;

      justify-content: space-between;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
}


.modal-message-body--col-name {
width: 60%;
  font-weight: bold;
}

.modal-message-body--col-date {
  width: 40%;
  text-align: right;
  color: #9098AC;
  font-size: 14px;
}

.modal-message-body--col-message {
  width: 100%;
  border-top: 1px solid #DDD;
  padding-top: 1em;
  margin-top: 1em;
}

h1.title-modal-message {
  margin: 0;
  font-size: 22px;
  padding: 0 0.6em;
}


.button-modal-message-primary {
  border-radius: 50px;
  padding: 1em 1.5em;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  margin: 0.5em;
  background-color: #F2AF2B;
  border: 1px solid #F2AF2B;
  color: #FFF;
  cursor: pointer;
}

.button-modal-message-primary:hover {
  background-color: #b3bcbf;
  border: 1px solid #b3bcbf;
}

.button-modal-message-secondary {
  border-radius: 50px;
  padding: 1em 1.5em;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  margin: 0.5em;
  background-color: #FFF;
  border: 1px solid #2CAA57;
  color: #222;
  cursor: pointer;
}

.button-modal-message-secondary:hover {
  color: #b3bcbf;
  border: 1px solid #b3bcbf;
}


/* CONTENT RESPONSE */

.container-modal-message__content-response {
  background-color: #ECECEC;
  padding: 23em 1em 4em 1em;
}

.content-response-instruction {
  font-size: 12px;
  color: #9098AC;
}

.content-response-instruction h2 {
  font-size: 12px;
  color: #9098AC;
}

/* .form-group-message {
  margin: 3em auto 1em auto;
  border-top: 1px solid #DADADA;
  padding-top: 1em;
} */

.container-modal-message__content-response .button-modal-message-primary {
  width: 150px
}

.form-group-message label {
  font-size: 14px;
}

.form-group-message textarea {
  width: 96%;
  font-family: 'Roboto' , Arial , Helvetica , sans-serif;
  padding: 0.8em;
  margin: 1em auto;
  background-color: transparent;
  color: #222;
  font-size: 14px;
  height: 100px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #E0E0E0;

  -webkit-appearance: none;
}

.form-group-message textarea::-webkit-input-placeholder {  
  color: #222; 
}

.form-group-message textarea:-moz-placeholder { 
  /* Firefox 18- */ color: #222;  
}

.form-group-message textarea::-moz-placeholder {  
  /* Firefox 19+ */   color: #222; 
}

.form-group-message textarea:-ms-input-placeholder {     
  color: #222;  
}

/* MQ 500px */

@media screen and (max-width: 500px) {
  .container-modal-message .modal-footer {
      text-align: left;
  }
}

@media screen and (max-width: 450px) {
  .modal-message-body--col-name {
    width: 100%;
  }

  .modal-message-body--col-date {
    width: 100%;
    text-align: left;
    margin-top: 0.5em;
  }

 .MuiDialog-paper { margin: 0 !important;}
 .MuiDialog-paperFullWidth {
    width: calc(100% - 25px) !important;
  }
  .MuiDialogContent-root {
    padding: 8px 8px !important;
  }

  .modal-message-body { padding: 2em 0.5em 4em 0.5em; font-size: 14px;;}
  h1.title-modal-message {padding: 0 0.25em; font-size: 15px;  }
  .modal-footer {padding: 0.5em;}
  .modal-message-body--col-date { font-size: 12px; }

  
}



/****************************/

.modal-message--compose-body {
  padding: 3em 1em 4em 1em; 
      display: flex;
      flex-wrap: wrap;
  align-items: center;

      justify-content: space-between;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
}


.button-modal-message-primary {
  border-radius: 50px;
  padding: 1em 1.5em;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  margin: 0.5em;
  background-color: #F2AF2B;
  border: 1px solid #F2AF2B;
  color: #FFF;
  cursor: pointer;
}

.button-modal-message-primary:hover {
  background-color: #b3bcbf;
  border: 1px solid #b3bcbf;
}

.button-modal-message-secondary {
  border-radius: 50px;
  padding: 1em 1.5em;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  margin: 0.5em;
  background-color: #FFF;
  border: 1px solid #2CAA57;
  color: #222;
  cursor: pointer;
}

.button-modal-message-secondary:hover {
  color: #b3bcbf;
  border: 1px solid #b3bcbf;
}

.button-modal-message-primary:disabled, .button-modal-message-primary:disabled:hover {
  background-color: #DDD;
  border: 1px solid #DDD;
  cursor: not-allowed;
}


/* CONTENT RESPONSE */

.container-modal-message__content-response {
  background-color: #ECECEC;
  padding: 2em 1em 4em 1em;
}

.content-response-instruction {
  font-size: 12px;
  color: #9098AC;
}

.content-response-instruction h2 {
  font-size: 12px;
  color: #9098AC;
}

.title-dropzone {
  font-size: 14px;
  font-weight: bold;
  color: #222;
  margin-top: 4em;
  padding-bottom: 0.5em;
  border-top: 1px solid #DDD;
  padding-top: 0.5em;
}

/* .form-group-message {
  margin: 3em auto 1em auto;
  border-top: 1px solid #DADADA;
  padding-top: 1em;
} */

.modal-message--compose-body .button-modal-message-primary {
  width: 150px
}

.form-group-message-compose {
  width: 100%;
}

.form-group-message-compose label {
  font-size: 14px;
}

.form-group-message-compose textarea {
  width: 96%;
  font-family: 'Roboto' , Arial , Helvetica , sans-serif;
  padding: 0.8em;
  margin: 1em auto;
  background-color: transparent;
  color: #222;
  font-size: 14px;
  height: 100px;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #E0E0E0;

  -webkit-appearance: none;
}



.modal-message--compose-body input {    
  width: 100%; 
  padding: 0.8em;
  margin: 1em auto;
  background-color: transparent;
  font-size: 14px;

  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #E0E0E0;

  -webkit-appearance: none;
}

.modal-message--compose-body input::-webkit-input-placeholder,
.modal-message--compose-body textarea::-webkit-input-placeholder {  
  color: #222; 
}

.modal-message--compose-body input:-moz-placeholder,
.modal-message--compose-body textarea:-moz-placeholder { 
  /* Firefox 18- */ color: #222;  
}

.modal-message--compose-body input::-moz-placeholder,
.modal-message--compose-body textarea::-moz-placeholder {  
  /* Firefox 19+ */   color: #222; 
}

.modal-message--compose-body input:-ms-input-placeholder,
.modal-message--compose-body textarea:-ms-input-placeholder {     
  color: #222;  
}

.modal-message--compose-body .selectTrello {
  width: 100%;
}

.content-dropzone {
  border: 0
}

.modal-message--compose-body .selectTrello .css-1wa3eu0-placeholder {
  color: #222;
  font-size: 14px;
}

.modal-message--compose-body .selectTrello .css-26l3qy-menu {
  font-size: 14px;
}



/* MQ 500px */

@media screen and (max-width: 500px) {
  .container-modal-message .modal-footer {
      text-align: left;
  }
}

.wrapper-student-progress h1 {
  font-weight: 800;
  font-size: 20px;
  margin: 0 0 1.5em 0;
  border-bottom: 1px solid #DDD;
  padding-bottom: 0.5em;
}

.wrapper-student-progress h2 {
  color: #2A426D;
  font-size: 16px;
  font-weight: bold;
}

.general-average {
  margin-bottom: 2em;
}

.general-average__row {
  display: flex;
  align-items: center;
}

.general-average__right {
  flex: 0 0 70px;
  padding: 0 1em;
  text-align: right;
  font-size: 23px;
  font-weight: bold;
}

.general-average__left {
  flex: 1 1 auto;
}

.progress-students {
  padding: 0;
  width: 100%;
  height: 14px;
  overflow: hidden;
  background: #FFF;
  border-radius: 26px;
  border: 1px solid #DDD;
}

.progress-students--bar {
  position: relative;
  float: left;
  min-width: 1%;
  height: 100%;
  background: #B0D8B9;
}

.wrapper-download {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-end;
}

.general-subject__mod-lessons a {
  background-color: #FFF;
  border: 3px solid #D9E8F2;
  border-radius: 10px;
  margin: 0.25em 0;
  text-decoration: none;
  display: flex;
    align-items: stretch;
    justify-content: space-between;
  position: relative;
}


.general-subject__mod-lessons a:hover {
  background-color: #D9E8F2;
}

.general-subject__mod-lessons .active {
  background-color: #D9E8F2;
}

.general-subject .wrapper-col {
  padding: 0;
  justify-content: space-between;
} 

.mod-lessons--left {
    width: 80%;
    padding: 0.25em 0;
    display: flex;
    align-items: center;
}

.mod-lessons--right {
  position: relative;
  display: flex;
  align-items: center;  
  border-bottom-right-radius: 7px;
  border-top-right-radius: 7px;
  min-width: 120px;
}


/* COLORS PROGRESS */
.bg-average {
  background-color: #5A93B7;
}

.bg-average--approved {
  background-color: #CCE4CC;
}

.txt-average--approved {
  color: #549454;
}

.bg-average--alert {
  background-color: #F3E7B7;
}
.txt-average {
  color: #FFF;
}
.txt-average--alert {
  color: #CFAF2A;
}

.bg-average--reprobate {
  background-color: #F7DBDA;
}

.txt-average--reprobate {
  color: #B92A24;
}

.bg-average--null {
  background-color: #ececec;
}

.txt-average--null {
  color: #b7b7b7;
}

/***/

.mod-lessons--title-lesson {
  font-size: 15px;
  color: #222;
  font-weight: bold;
  margin-left: 0.25em;
}

.mod-lessons--lesson-grade {
  color: #FFF;
  font-size: 17px;
  padding: 0 0.8em;
  font-weight: bolder;
}

.mod-lessons--left img, .mod-lessons--left div {
    vertical-align: middle;
    display: inline-block;
}

.mod-lessons--title-lesson {
  /* width: 100px; */
  width: 120px;
}

/*.mod-lessons--title-lesson {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 50px;
  white-space: nowrap;
}
*/
.mod-lessons--left img {
  width: 53px;
  margin-left: 0.5em;
}


.mod-lessons--right span {
 margin: auto  
}


/* COL RIGHT */
.wrapper-detail-average {
  background-color: #f7f7f7;
  padding: 1.5em 1.5em 5em;
}

.wrapper-detail-average h3 {
  font-weight: 800;
  font-size: 16px;
  margin: 0 0 .25em;
}

.wrapper-detail-average h4 {
  font-weight: 600;
  font-size: 11px;
  margin: 0 0 2em;
}

/* TABLE */
.detail-average__table {
  border-collapse: collapse;
  width: 100%;
  margin: 2em 0;
}

.detail-average__table tr {
  text-align: left;
  vertical-align: top;
  font-size: 14px;
  border-bottom: 1px solid #EBEBEB;
}

.detail-average__table thead tr {
  color: #2A426D;
  border-bottom: 0;
  font-size: 13px;
}

.detail-average__table p {
  margin: 0.25em 0
}

.detail-average__table td {
  padding: 1em 0;
}

.detail-average__table td:nth-child(2)  {
    font-weight: bold;
}

.general-subject__title-search {
    border-bottom: 1px solid #DDD;
    padding: 0.5em 0;
    text-decoration: none;
    color: #2A426D;
    font-size: 16px;
    background-color: #FFF;
    font-weight: bold;
    margin-bottom: 1em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title-search__col-right {
    /* width: 200px; */
    width: 210px;
    text-align: center;
}

.message-note {
  background-color: #EBF5F4;
  padding: 2px 5px 5px 7px;
  border-radius: 4px;
  color: #66A6A1;
  font-weight: 500;
}




/* MQ 1260px */

@media screen and (max-width: 1260px) {
   .general-subject .two-col {width: 100%}   
}

/* MQ 920px */

@media screen and (max-width: 920px) {
   .general-subject .two-col {width: 48%}   
}


/* MQ 700px */

@media screen and (max-width: 700px) {
   .general-subject .two-col {width: 100%}   
}

/* MQ 500px */

@media screen and (max-width: 500px) {
   table.detail-average__table, .detail-average__table thead, 
  .detail-average__table tbody, 
  .detail-average__table th, 
  .detail-average__table td, 
  .detail-average__table tr { 
    display: block; 
  }

  .detail-average__table thead tr { 
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .detail-average__table td:nth-child(2)  {
    padding-top: 0;
    font-size: 15px;
  }
}


.title-table-progress {
    color: #2A426D;
    font-size: 16px;
}

.content-table-homework td.table-progress--title {
    color: #000;
    font-weight: bold;
    font-size: 16px !important;
}

.content-table-homework.content-table-progress th {
    font-size: 12px;
}

.content-table-homework.content-table-progress td {
    font-size: 14px;
    color: #000;
    font-weight: 600;
}

.letter-progress {
    background-color: #E4E4E4;
    padding: 2px 5px;
    font-weight: bold;
    margin-left: 5px;
}

.footer-table-progress .letter-progress {
    background-color: #FFF;
}

.footer-table-progress {
    background-color: #E7F1EF;
}

.content-table-homework.content-table-progress tr {
    padding-right: 5px;
}

.wrapper-download .button-primary {
    font-weight: 600;
}

@media screen and (max-width: 750px) {
    .content-table-progress tr {
        padding: 1em;
    }
    .content-table-progress td { 
        border: none;
        /* border-bottom: 1px solid #eee;  */
        position: relative;
        padding-left: 0px;
        padding-top: 10px !important;
        padding-bottom: 0px !important; 
    }

    .content-table-progress td:before { 
        position: relative;
        top: 0px;
        left: 0px;
        /* width: 120px;  */
        padding-right: 10px; 
        white-space: nowrap;
        font-size: 11px;
        font-weight: bold;
    }

    .content-table-homework.content-table-progress tr.footer-table-progress {
        background-color: #E7F1EF;
    }

    .content-table-progress td:nth-of-type(1):before { display: none; }
    .content-table-progress td:nth-of-type(2):before { content: "1er Período:"; }
    .content-table-progress td:nth-of-type(3):before { content: "2do Período:"; }
    .content-table-progress td:nth-of-type(4):before { content: "3er Período"; }
    .content-table-progress td:nth-of-type(5):before { content: "4to Período"; }
    .content-table-progress td:nth-of-type(6):before { content: "5to Período"; }
    .content-table-progress td:nth-of-type(7):before { content: "6to Período"; }
    .content-table-progress td:nth-of-type(8):before { content: "Cumulativo"; }
}
.wrapper-content {
  margin-left: 150px;
  margin-top: 75px;
}

/****************************/

.center-container {
  padding: 1em;
  padding-bottom: 75px; 
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
}


.container_two-col {
width: 48%;
}



/* MQ 920px */

@media screen and (max-width: 920px) {
  .container_two-col {
    width: 100%;
    margin-bottom: 1em;
}
}


/* MQ 750px */

@media screen and (max-width: 750px) {
  .wrapper-content {
      margin-left: 0;
  }

  .container_two-col {
    width: 100%;
  }

  .center-container {
    padding-bottom: 125px;
  }
}

/* MQ 500px */

@media screen and (max-width: 500px) {
  .center-container {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
}


/* FONT SIZE CHANGER */

/* .content-font-size-changer {
  position: fixed;
  z-index: 3;
  right: 0.5em;
  background-color: #DDD;
  padding: 0.5em;
} */

.content-font-size-changer {
  padding: 0.5em;
  margin-top: -18px;
  text-align: right;
  height: 25px;
  display: none;
}

.font-size-changer {
  margin-top: -15px;
  /* position: absolute; */  
  position: relative;  
  height: 35px !important;
  width: 100% !important;
}

.wrapper-iframe .font-size-changer {
  width: 100%;
}

.font-size-changer-buttons {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.center-container .font-size-changer .font-size-changer-buttons > div {
  border: 1px solid #DDD;
  line-height: 30px;
  font-weight: bold;
  color: #27466C;
  background-color: #EFEFEF;
}

.center-container .font-size-changer .font-size-changer-buttons > div:hover {
  color: #A2A2A2;
}

.center-container .font-size-changer .font-size-changer-buttons .font-size-up span {
  font-size: 30px !important;
}

.center-container .font-size-changer .font-size-changer-buttons .font-size-up,
.center-container .font-size-changer .font-size-changer-buttons .font-size-down {
  height: 33px !important;
}

.content .vviYD {
  margin-top: 0 !important;
}


/* CHAT STYLE */
#chat-widget-container{
  bottom: 50px !important;
}

@media screen and (max-width: 750px) {
  .lc-dw9r6j {
      height: 80% !important;
      margin-top: 20%;
  }

  #chat-widget-container {
      margin-bottom: 55px !important
  }
}

/* FIX CHAT HEIGHT */
@media screen and (max-width: 450px) {
    #chat-widget-container {
        height: 530px !important;
    }
}

@media screen and (max-width: 450px) {
#target-one img {
  max-width: 300px;
}

.content-form img {
  max-width: 200px;
}
}
.wrapper-iframe {
  width: 100%;
  max-width: 720px;
  margin: -35px 0 0 10%;
  padding: 0 1.5em 0.5em 1.5em;
  color: #2C3140;
  position: relative;
}

.wrapper-iframe p {
  line-height: 1.5em;
  font-size: 18px;
  margin-bottom: 1.5em;
}

.wrapper-iframe b {
  border-bottom: 1px solid #2C3140;
  font-size: 20px;
  display: block;
}

.wrapper-iframe video {
  width: 100% !important;
  height: auto;
  margin: 0.5em 0 !important;
}

.wrapper-iframe h1, .wrapper-iframe h2, .wrapper-iframe h3 {
  border-bottom: 1px solid #E7E7E7;
  padding-bottom: 0.25em;    
}

.wrapper-iframe h1, 
.wrapper-iframe h2,
.wrapper-iframe h3,
.wrapper-iframe h4 {
  color: #40539D;
  margin: 1em 0 1.5em 0;
}


.wrapper-iframe ul {
  margin: 2em 0;
}

.wrapper-iframe li {
  margin-bottom: 0.5em;
}

.wrapper-iframe a {
  color: #DC8520;
  text-decoration: underline;
}

.wrapper-iframe a:hover {
  text-decoration: none;
}


.wrapper-iframe table { 
  width: 100%; 
  border-collapse: collapse; 
  background: #F7F7F7;
}

/* Zebra striping */
.wrapper-iframe tr:nth-of-type(odd) { 
  background: #FFF; 
}

.wrapper-iframe th { 
  background: #BEC9F8; 
  color: #161E60; 
  font-weight: bold; 
}

.wrapper-iframe td, .wrapper-iframe th { 
  padding: 10px 20px; 
  border: 1px solid #DDD; 
  text-align: center; 
}

.wrapper-iframe img {
  display: block;
  margin: 1em 0;
}

@media screen and (max-width: 750px) { 
  .wrapper-iframe {
    margin: -35px 0 0 0;
  }
}

/* IFRAME KIDS */
.wrapper-iframe-kids {
  width: 100%;
  max-width: 720px;
  margin: -35px 0 0 10%;
  padding: 0 1.5em 0.5em 1.5em;
  color: #2C3140;
  font-family: 'Quicksand', sans-serif;
}

.wrapper-iframe-kids video {
  width: 100% !important;
  height: 400px;
  margin: 0.5em 0 !important;
}

.wrapper-iframe-kids p {
  line-height: 1.6em;
  font-size: 20px;
  margin-bottom: 1.5em;
}

.wrapper-iframe-kids h1, .wrapper-iframe-kids h2, .wrapper-iframe-kids h3 {
  border-bottom: 1px solid #E7E7E7;
  padding-bottom: 0.25em;    
}

.wrapper-iframe-kids h1, 
.wrapper-iframe-kids h2,
.wrapper-iframe-kids h3,
.wrapper-iframe-kids h4 {
  color: #40539d;
  margin: 1em 0 1.5em 0;
}

.wrapper-iframe-kids h4, .wrapper-iframe-kids--FullWidth h4 {
  background-color: #e8eff3;
  font-size: 18px;
  padding: 0.5em;
  color: #7982a9;
}

.wrapper-iframe-kids h2 {
  font-size: 22px;
}

.wrapper-iframe-kids h3 {
  font-size: 20px;
}

.wrapper-iframe-kids ul {
  margin: 2em 0;
  font-size: 20px;
}

.wrapper-iframe-kids li {
  margin-bottom: 0.5em;
}

.wrapper-iframe-kids a {
  color: #DC8520;
  text-decoration: underline;
}

.wrapper-iframe-kids a:hover {
  text-decoration: none;
}


.wrapper-iframe-kids table { 
  width: 100%; 
  border-collapse: collapse; 
  background: #FFF;
  font-size: 20px;
}

.wrapper-iframe-kids th { 
  background: #BEC9F8; 
  color: #161E60; 
  font-weight: bold; 
}

.wrapper-iframe-kids td, .wrapper-iframe-kids th { 
  padding: 10px 20px; 
  border: 1px solid #DDD; 
  text-align: center; 
}

.wrapper-iframe-kids img {
  display: block;
  margin: 1em 0;
}



.wrapper-iframe .lesson-title-subject {
  background-color: #e8eff3;
  font-size: 16px;
  padding: 0.7em;
  color: #27466D;
}

.lesson-title-subject img {
  display: inline-block;
  width: 35px;
  vertical-align: middle;
  margin: 0;
  padding-right: 10px;
  margin: 0 !important;
}

.lesson-title-subject {
  display: flex;
  align-items: center;
}

.lesson-title-subject__left, .lesson-title-subject__right {
  vertical-align: middle;
}


.lesson-title-subject .lesson-title-subject__lesson {
  padding-left: 0.8em;
}

.lesson-title-subject .lesson-title-subject__subject {
  font-weight: bold;
}

.vviYD {
  padding: 0 !important;
}

.only-image-inline img {
  display: inline-block !important;
  vertical-align: middle !important;
   padding: 0 0.8em;
}

/* .wrapper-iframe h4.vviYD,
.wrapper-iframe-kids h4.vviYD {
  margin-bottom: 0;
} */

.wrapper-iframe-kids .vviYD,
.wrapper-iframe-kids--FullWidth .vviYD {
  padding: 0.7em !important;
}

@media screen and (max-width: 750px) { 
  .wrapper-iframe-kids {
      margin: -35px 0 0 0;
  }
}

@media screen and (max-width: 450px) { 
  .wrapper-iframe-kids {
    padding: 0 0.5em 0.5em 0.5em;
  }

  .wrapper-iframe-kids p, .wrapper-iframe-kids table,
  .wrapper-iframe-kids ul { font-size: 18px; }

  .wrapper-iframe-kids h4, .wrapper-iframe-kids--FullWidth h4 {
    font-size: 15px;
  }
}
.wrapper-iframe .lesson-title-subject {
  background-color: #e8eff3;
  font-size: 16px;
  padding: 0.7em;
  color: #27466D;
}

.lesson-title-subject img {
  display: inline-block;
  width: 35px;
  vertical-align: middle;
  margin: 0;
  padding-right: 10px;
  margin: 0 !important;
}

.lesson-title-subject {
  display: flex;
  align-items: center;
}

.lesson-title-subject__left, .lesson-title-subject__right {
  vertical-align: middle;
}


.lesson-title-subject .lesson-title-subject__lesson {
  padding-left: 0.8em;
}

.lesson-title-subject .lesson-title-subject__subject {
  font-weight: bold;
}
.wrapper-menu {
  width: 135px;
  background-color: #4E66C4;
  position: fixed;
  height: 100%;
  top: 58px;
  z-index: 2;
}

/****************************/

.navigation-menu {
  text-align: center;
  color: #FFF;
  font-size: 13px;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 12px;
}

.navigation-menu li {
  padding: 0.5em;
  display: block;
}

.navigation-menu li a {
  color: #FFF;
  text-decoration: none;
  cursor: pointer;
}

.navigation-menu li img {
  display: block;
  margin: auto;
  width: 30%;
  height: auto;
  vertical-align: top;
}

.navigation-menu li span.lesson-mark img {display: none;}

.navigation-menu li.active span.lesson-mark img {
  float: right;
  margin-top: -45px;
  margin-right: -20px;
  width: 39px;
  display: block;
  background-color: transparent;
}

.navigation-menu li a:hover img, .navigation-menu li.active img {
  background-color: #40539D;
  border-radius: 15px;
}

li.highlight-item a {
  color: #FFF700;
}


/* MQ 750px */

@media screen and (max-width: 750px) {
  .wrapper-menu {
      width: 100%;
      height: 80px;
      bottom: 12px /* 28 change for delete txt*/;
      top: auto;
  }

  .navigation-menu li img {
      width: 40px;
      height: 40px;
  }

  .navigation-menu {
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
        flex-grow: 1;
        align-items: stretch;
  }

  .navigation-menu li {
      width: 100%;
      text-align: center;
      padding: 0.25em 0 0.6em 0;
  }

  .navigation-menu li.active span.lesson-mark img {
      display: none;
  }

  .navigation-menu li.active {
      background-color: #40539D;
  }
}

/* MQ 500px */

@media screen and (max-width: 500px) {
  .wrapper-menu {
    height: 75px /* 78 change for delete txt*/;
  }

  .navigation-menu {
      font-size: 11px;
      letter-spacing: 0.3px;
  }


  .navigation-menu li.active span.lesson-mark img {
      margin-top: 0;
  }
}

/* MQ 480px */

@media screen and (max-width: 480px) {
  .wrapper-menu {
    bottom: 15px /* 21 change for delete txt*/;
    }

  .navigation-menu .navigation-menu--item {
    display: none;
  }

  .navigation-menu li img {
    width: 35px;
  }
}


.wrapper-iframe {
  width: 100%;
  max-width: 720px;
  margin: 0 0 0 10%;
  padding: 0 1.5em 0.5em 1.5em;
  color: #2C3140;
  position: relative;
}

.wrapper-iframe p {
  line-height: 1.5em;
  font-size: 18px;
  margin-bottom: 1.5em;
}

.wrapper-iframe h1, .wrapper-iframe h2, .wrapper-iframe h3 {
  border-bottom: 1px solid #E7E7E7;
  padding-bottom: 0.25em;    
}

.vocabulary-title {
  display: block;
  font-weight: bold;
  font-size: 20px;
  color: #40539D;
  border-bottom: 1px solid #ececec;
  padding-bottom: 0.25em;
  margin-top: 2em;
}

.wrapper-iframe h1, 
.wrapper-iframe h2,
.wrapper-iframe h3,
.wrapper-iframe h4 {
  color: #40539D;
  margin: 1em 0 1.5em 0;
}

.wrapper-iframe ul {
  margin: 2em 0;
}

.wrapper-iframe li {
  margin-bottom: 0.5em;
}

.wrapper-iframe a {
  color: #DC8520;
  text-decoration: underline;
}

.wrapper-iframe a:hover {
  text-decoration: none;
}


.wrapper-iframe table { 
  width: 100%; 
  border-collapse: collapse; 
  background: #F7F7F7;
}

/* Zebra striping */
.wrapper-iframe tr:nth-of-type(odd) { 
  background: #FFF; 
}

.wrapper-iframe th { 
  background: #BEC9F8; 
  color: #161E60; 
  font-weight: bold; 
}

.wrapper-iframe td, .wrapper-iframe th { 
  padding: 10px 20px; 
  border: 1px solid #DDD; 
  text-align: center; 
}

.wrapper-iframe img {
  display: block;
  margin: 1em 0;
}

@media screen and (max-width: 750px) { 
  .wrapper-iframe {
    margin: -35px 0 0 0;
  }
}

@media screen and (max-width: 450px) { 
  .wrapper-iframe {
    padding: 0 0.5em 0.5em 0.5em;
  }
}


.wrapper-iframe-kids {
  width: 100%;
  max-width: 720px;
  margin: 0 0 0 10%;
  padding: 0 1.5em 0.5em 1.5em;
  color: #2C3140;
  font-family: 'Quicksand', sans-serif;
}

.wrapper-iframe-kids p {
  line-height: 1.6em;
  font-size: 20px;
  margin-bottom: 1.5em;
}

.wrapper-iframe-kids h1, .wrapper-iframe-kids h2, .wrapper-iframe-kids h3 {
  border-bottom: 1px solid #E7E7E7;
  padding-bottom: 0.25em;    
}

.wrapper-iframe-kids h1, 
.wrapper-iframe-kids h2,
.wrapper-iframe-kids h3,
.wrapper-iframe-kids h4 {
  color: #40539d;
  margin: 1em 0 1.5em 0;
}

.wrapper-iframe-kids h4, .wrapper-iframe-kids--FullWidth h4 {
  background-color: #e8eff3;
  font-size: 18px;
  padding: 0.5em;
  color: #7982a9;
}

.wrapper-iframe-kids h2 {
  font-size: 22px;
}

.wrapper-iframe-kids h3 {
  font-size: 20px;
}

.wrapper-iframe-kids ul {
  margin: 2em 0;
  font-size: 20px;
}

.wrapper-iframe-kids li {
  margin-bottom: 0.5em;
}

.wrapper-iframe-kids a {
  color: #DC8520;
  text-decoration: underline;
}

.wrapper-iframe-kids a:hover {
  text-decoration: none;
}


.wrapper-iframe-kids table { 
  width: 100%; 
  border-collapse: collapse; 
  background: #FFF;
  font-size: 20px;
}

.wrapper-iframe-kids th { 
  background: #BEC9F8; 
  color: #161E60; 
  font-weight: bold; 
}

.wrapper-iframe-kids td, .wrapper-iframe-kids th { 
  padding: 10px 20px; 
  border: 1px solid #DDD; 
  text-align: center; 
}

.wrapper-iframe-kids img {
  display: block;
  margin: 1em 0;
}

.wrapper-iframe--FullWidth, .wrapper-iframe-kids--FullWidth {
  width: 100%;
  max-width: 95%;
  margin: 0 0 0 0;
  padding: 0 1.5em 0.5em 1.5em;
  color: #2C3140;
}

.wrapper-iframe-kids--FullWidth {
  font-family: 'Quicksand', sans-serif;
}

@media screen and (max-width: 750px) { 
  .wrapper-iframe-kids {
    margin: -35px 0 0 0;
}
}

@media screen and (max-width: 450px) { 
  .wrapper-iframe-kids {
    padding: 0 0.5em 0.5em 0.5em;
  }

  .wrapper-iframe-kids p, .wrapper-iframe-kids table,
  .wrapper-iframe-kids ul { font-size: 18px; }

  .wrapper-iframe-kids h4, .wrapper-iframe-kids--FullWidth h4 {
    font-size: 15px;
  }
}

.wrapper-iframe {
  width: 100%;
  max-width: 720px;
  margin: -35px 0 0 10%;
  padding: 0 1.5em 0.5em 1.5em;
  color: #2C3140;
  position: relative;
}

.wrapper-iframe p {
  line-height: 1.5em;
  font-size: 18px;
  margin-bottom: 1.5em;
}

.vocabulary-title {
  display: block;
  font-weight: bold;
  font-size: 20px;
  color: #40539D;
  border-bottom: 1px solid #ececec;
  padding-bottom: 0.25em;
  margin-top: 2em;
}


.wrapper-iframe h1, .wrapper-iframe h2, .wrapper-iframe h3 {
  border-bottom: 1px solid #E7E7E7;
  padding-bottom: 0.25em;    
}

.wrapper-iframe h1, 
.wrapper-iframe h2,
.wrapper-iframe h3,
.wrapper-iframe h4 {
  color: #40539D;
  margin: 1em 0 1.5em 0;
}


.wrapper-iframe ul {
  margin: 2em 0;
}

.wrapper-iframe li {
  margin-bottom: 0.5em;
}

.wrapper-iframe a {
  color: #DC8520;
  text-decoration: underline;
}

.wrapper-iframe a:hover {
  text-decoration: none;
}


.wrapper-iframe table { 
  width: 100%; 
  border-collapse: collapse; 
  background: #F7F7F7;
}

/* Zebra striping */
.wrapper-iframe tr:nth-of-type(odd) { 
  background: #FFF; 
}

.wrapper-iframe th { 
  background: #BEC9F8; 
  color: #161E60; 
  font-weight: bold; 
}

.wrapper-iframe td, .wrapper-iframe th { 
  padding: 10px 20px; 
  border: 1px solid #DDD; 
  text-align: center; 
}

.wrapper-iframe img {
  display: block;
  margin: 1em 0;
}

@media screen and (max-width: 750px) { 
  .wrapper-iframe {
    margin: -35px 0 0 0;
  }
}

@media screen and (max-width: 450px) { 
  .wrapper-iframe {
    padding: 0 0.5em 0.5em 0.5em;
  }
}


.wrapper-iframe-kids {
  width: 100%;
  max-width: 720px;
  margin: -35px 0 0 10%;
  padding: 0 1.5em 0.5em 1.5em;
  color: #2C3140;
  font-family: 'Quicksand', sans-serif;
}

.wrapper-iframe-kids p {
  line-height: 1.6em;
  font-size: 20px;
  margin-bottom: 1.5em;
}

.wrapper-iframe-kids h1, .wrapper-iframe-kids h2, .wrapper-iframe-kids h3 {
  border-bottom: 1px solid #E7E7E7;
  padding-bottom: 0.25em;    
}

.wrapper-iframe-kids h1, 
.wrapper-iframe-kids h2,
.wrapper-iframe-kids h3,
.wrapper-iframe-kids h4 {
  color: #40539d;
  margin: 1em 0 1.5em 0;
}

.wrapper-iframe-kids h4, .wrapper-iframe-kids--FullWidth h4 {
  background-color: #e8eff3;
  font-size: 18px;
  padding: 0.5em;
  color: #7982a9;
}

.wrapper-iframe-kids h2 {
  font-size: 22px;
}

.wrapper-iframe-kids h3 {
  font-size: 20px;
}

.wrapper-iframe-kids ul {
  margin: 2em 0;
  font-size: 20px;
}

.wrapper-iframe-kids li {
  margin-bottom: 0.5em;
}

.wrapper-iframe-kids a {
  color: #DC8520;
  text-decoration: underline;
}

.wrapper-iframe-kids a:hover {
  text-decoration: none;
}


.wrapper-iframe-kids table { 
  width: 100%; 
  border-collapse: collapse; 
  background: #FFF;
  font-size: 20px;
}

.wrapper-iframe-kids th { 
  background: #BEC9F8; 
  color: #161E60; 
  font-weight: bold; 
}

.wrapper-iframe-kids td, .wrapper-iframe-kids th { 
  padding: 10px 20px; 
  border: 1px solid #DDD; 
  text-align: center; 
}

.wrapper-iframe-kids img {
  display: block;
  margin: 1em 0;
}

.wrapper-iframe--FullWidth, .wrapper-iframe-kids--FullWidth {
  width: 100%;
  max-width: 95%;
  margin: -35px 0 0 0;
  padding: 0 1.5em 0.5em 1.5em;
  color: #2C3140;
}

.wrapper-iframe-kids--FullWidth {
  font-family: 'Quicksand', sans-serif;
}

@media screen and (max-width: 750px) { 
  .wrapper-iframe-kids {
    margin: -35px 0 0 0;
}
}

@media screen and (max-width: 450px) { 
  .wrapper-iframe-kids {
    padding: 0 0.5em 0.5em 0.5em;
  }

  .wrapper-iframe-kids p, .wrapper-iframe-kids table,
  .wrapper-iframe-kids ul { font-size: 18px; }

  .wrapper-iframe-kids h4, .wrapper-iframe-kids--FullWidth h4 {
    font-size: 15px;
  }

  .wrapper-iframe--FullWidth, .wrapper-iframe-kids--FullWidth {
    width: 100%;
    max-width: 100%;
    padding: 0 0em 0.5em 0em;
  }

}


.wrapper-quiz-list {
  width: 100%;
  margin: 0;
  padding: 0;
  color: #2C3140;
}

.wrapper-quiz-list h4.title-lessons {
  background-color: #e8eff3;
  font-size: 16px;
  padding: 0.7em;
  color: #253084;
}

.wrapper-quiz-list h3 {
font-weight: 800;
font-size: 16px;
margin: 0 0 2em 0;
}


/**********/

.container-quiz-lesson a {
border-bottom: 1px solid #DDD;
padding: 1em ;
text-decoration: none;
color: #222;
background-color: #FFF;
font-size: 15px; 
cursor: default;
display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-quiz-lesson a .col-left--quiz-lesson span {
  cursor: pointer;
}

.container-quiz-lesson a:hover {
background-color: #D9E8F2;
box-shadow: inset -1px -1px 13px -1px rgba(184,202,214,1);
}

/* Container Disabled */

.container-quiz-lesson a.disabled {
  background-color: #EDEDED;
  color: rgba(94, 94, 94, .6);
  cursor: not-allowed;
} 

.container-quiz-lesson a:hover.disabled {
  background-color: #EDEDED;
  box-shadow: none;
}

.container-quiz-lesson a.disabled .quiz-lesson__date,
.container-quiz-lesson a.disabled p.quiz-lesson__download,
.container-quiz-lesson a.disabled .quiz-lesson__icon-file-download__pdf,
.container-quiz-lesson a.disabled .quiz-lesson__icon-file-download__word  {
  color: rgba(94, 94, 94, .6);
  cursor: not-allowed;
} 

.container-quiz-lesson a.disabled .quiz-lesson__download img {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}


/***/

.container-quiz-lesson:first-child a {
border-top-left-radius: 15px;
border-top-right-radius: 15px;
}

.container-quiz-lesson:last-child a {
border-bottom-left-radius: 15px;
border-bottom-right-radius: 15px;
border: 0;
}


.col-left--quiz-lesson {
width: 60%;
}

.col-with-fix--quiz-lesson {
  width: 155px;
}

.col-left--quiz-lesson p span:hover {
  cursor: pointer;
}

.col-right--quiz-lesson {
width: 160px;
text-align: center;
}


.container-quiz-lesson .icon-finished-lesson {
font-size: 25px;
color: #B0D8B9;
}

.quiz-lesson__date {
  color: #9098AC;
  font-size: 12px;
}

p.quiz-lesson__download {
  margin: 0;
  font-size: 11px;
  font-weight: 500;
  margin-top: 5px;
}

.quiz-lesson__download--btn {
  display: flex
}

.quiz-lesson__download--btn div:hover {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}

.quiz-lesson__download img {
  display: inline-block;
  width: 20px;
  margin: 0;
  margin-right: 2px;
  vertical-align: middle;
}

.quiz-lesson__icon-finish {
  color: #B0D8B9;
  font-size: 22px;
}

.quiz-lesson__icon-waiting {
  color: #AFAFAF;
  font-size: 22px;
}

.quiz-lesson__icon-file-download {
  font-size: 14px;
  margin-right: 5px;
}

.quiz-lesson__icon-file-download__word {
  color: #305190;
}

.quiz-lesson__icon-file-download__pdf {
  color: #E45142;
}

.quiz-lesson__download-txt {
  margin-right: 10px;
}


/* MQ 500px */

@media screen and (max-width: 500px) {
.container-quiz-lesson a {
  flex-wrap: wrap;
}

.col-left--quiz-lesson {
  width: 100%;
  margin-bottom: 1em;
}
}



.wrapper-quiz-list {
  width: 100%;
  margin: 0;
  padding: 0;
  color: #2C3140;
}

.wrapper-quiz-list h4.title-lessons {
  background-color: #e8eff3;
  font-size: 16px;
  padding: 0.7em;
  color: #253084;
}

.wrapper-quiz-list h3 {
font-weight: 800;
font-size: 16px;
margin: 0 0 2em 0;
}


/**********/

.container-quiz-lesson a {
border-bottom: 1px solid #DDD;
padding: 1em ;
text-decoration: none;
color: #222;
background-color: #FFF;
font-size: 15px; 
cursor: default;
display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-quiz-lesson a:hover {
background-color: #D9E8F2;
box-shadow: inset -1px -1px 13px -1px rgba(184,202,214,1);
}

.container-quiz-lesson:first-child a {
border-top-left-radius: 15px;
border-top-right-radius: 15px;
}

.container-quiz-lesson:last-child a {
border-bottom-left-radius: 15px;
border-bottom-right-radius: 15px;
border: 0;
}


.col-left--quiz-lesson {
width: 60%;
}

.col-right--quiz-lesson {
width: 160px;
text-align: center;
}


.container-quiz-lesson .icon-finished-lesson {
font-size: 25px;
color: #B0D8B9;
}



/* MQ 500px */

@media screen and (max-width: 500px) {
.container-quiz-lesson a {
  flex-wrap: wrap;
}

.col-left--quiz-lesson {
  width: 100%;
  margin-bottom: 1em;
}
}


.wrapper-list-w-icon {
  width: 100%;
}

/*****************/

.list-w-icon {
border-bottom: 1px solid #DDD;
padding: 0.5em 1em;
text-decoration: none;
color: #222;
background-color: #FFF;
font-size: 14px !important;
display: flex;
  align-items: center;

}

.list-w-icon a {
color: #27466C;
font-weight: bold;
text-decoration: none;
font-size: 15px !important; 
}

.list-w-icon a:hover {
color: #5A93B6;
}

.list-w-icon__right {
  width: 130px;
}

.list-w-icon__right img {
  padding: 0.5em;
}

.list-w-icon__left {}

.list-w-icon__left p {
margin: 0.25em 0;
line-height: 1.3em;
}

.list-w-icon__txt-ppal {
  font-size: 16px !important;
  font-weight: bold;
}

.bg-list-offline {
  display: block;
  margin: 1em auto;
  width: 100%;
  text-align: center;
}

.bg-list-offline img {
  width: 100%;
  max-width: 390px;
  margin: auto;
}

/* MQ 500px */

@media screen and (max-width: 500px) {
.list-w-icon {
flex-wrap: wrap;
}

.list-w-icon__right {  display: block; }

.list-w-icon__left { width: 100%; }
}

/* MQ 450px */

@media screen and (max-width: 450px) {
.list-w-icon__txt-ppal {
  font-size: 14px;
}

.list-w-icon { font-size: 12px; }
}

.wrapper-aditional-list-w-icon {
  width: 100%;
}

/*****************/

.aditional-list-w-icon__content {
border-bottom: 1px solid #DDD;
padding: 0.5em 1em;
text-decoration: none;
color: #222;
background-color: #FFF;
font-size: 15px;
display: flex;
  align-items: center;

}

.aditional-list-w-icon__content a {
color: #27466C;
font-weight: bold;
text-decoration: none;
font-size: 18px !important;
}

.aditional-list-w-icon__content a:hover {
color: #5A93B6;
}

.aditional-list__right {
  width: 100px;
}

.aditional-list__right img {
  padding: 0.5em;
  width: 85px
}

.aditional-list__left {}

.aditional-list__left p {
margin: 0.25em 0;
font-size: 14px !important;
}

.aditional-list__txt-ppal {
  font-size: 18px !important;
  font-weight: bold;
}



/* MQ 500px */

@media screen and (max-width: 500px) {
.aditional-list-w-icon__content {
flex-wrap: wrap;
}

.aditional-list__right {  display: block; }

.aditional-list__left { width: 100%; }
}

/* MQ 450px */

@media screen and (max-width: 450px) {
.aditional-list__txt-ppal {
  font-size: 16px;
}

.aditional-list-w-icon__content { font-size: 14px; }
}

.container-modal-tries {
    width: 90%;
    max-width: 550px;
    background-color: #FFF;
    margin: 2em auto;
    padding: 1em;
    box-shadow: 0 2px 7px 0 rgba(0,0,0,0.26);
    border-radius: 15px;
  }
  
  /****************************/
  
  .modal-tries-body {
    padding: 1em;
    line-height: 1.5em;
    font-size: 16px;
    color: #222
  }
  
  
  h3.title-modal-tries {
    display: block;
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 0;
  }
  
  .modal-footer-tries {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1em;
  }
  
.wrapper-iframe-form {
  width: 100%;
  max-width: 720px;
  margin: -35px 0 0 10%;
  padding: 0 1.5em 0.5em 1.5em;
  color: #2C3140;
}

.wrapper-iframe-form span {
  line-height: 1.5em;
  font-size: 18px;
  margin-bottom: 0;
  margin: 0.5em 0;
}

.wrapper-iframe-form h1, .wrapper-iframe-form h2, .wrapper-iframe-form h3 {
  border-bottom: 1px solid #E7E7E7;
  padding-bottom: 0.25em;    
}

.wrapper-iframe-form h1, 
.wrapper-iframe-form h2,
.wrapper-iframe-form h3,
.wrapper-iframe-form h4 {
  color: #40539D;
  margin: 1em 0 1.5em 0;
}

.instruction {
  font-style: italic;
  font-size: 15px;
  color: #666;
  padding-bottom: 1em;
}

.question, .quiz-title {
  /* font-weight: bold; */
  color: #000;
  padding-bottom: 1em;
  font-size: 20px;
}

h4.title-page {
  background-color: #e8eff3;
  font-size: 16px;
  padding: 0.7em;
  color: #253084;
}

.wrapper-iframe-form ul {
  margin: 2em 0;
}

.wrapper-iframe-form li {
  margin-bottom: 0.5em;
}

.wrapper-iframe-form a {
  color: #DC8520;
  text-decoration: underline;
}

.wrapper-iframe-form a:hover {
  text-decoration: none;
}


.wrapper-iframe-form img {
  display: block;
  margin: 1em 0;
}

.content-form {
  background-color: #F7F7F7;
  padding: 1em;
  margin-bottom: 1em;
}

.wrapper-iframe-form textarea {
  display: block;
  width: 98%;
  border: 0;
  padding: 10px 5px;
  background: #F7F7F7 no-repeat;
  font-family: 'Roboto' , Arial , Helvetica , sans-serif;
  font-size: 18px;
  
  background-image: linear-gradient(to bottom, #7C89BF, #40539D), linear-gradient(to bottom, #DDD, #DDD);
  /* sizes for the 2 images (default state) */
  background-size: 0 2px, 100% 1px;
  /* positions for the 2 images. Change both "50%" to "0%" or "100%" and tri again */
  background-position: 50% 100%, 50% 100%;
  /* animation solely on background-size */
  transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
}

.wrapper-iframe-form input:focus,
.wrapper-iframe-form textarea:focus{
/* sizes for the 2 images (focus state) */
  background-size: 100% 2px, 100% 1px;
  outline: none;
}

.quiz_radio-button {
  display: block;
  padding-bottom: 0.2em;
}
.quiz_radio-button label, .quiz_radio-button input {
  display: inline-block; 
  vertical-align: top;
}
.quiz_radio-button label {width: 90%;}
.quiz_radio-button input {margin-right: 15px; }
.quiz_radio-button p, .quiz_radio-button img { margin: 0; padding: 0;}


.quiz_radio-button input[type=radio]:checked ~ label{
  color: #40539D;
  font-weight: bold;
}

.quiz_radio-button input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 6px;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #CCC;
  background-color: #CCC;
  border-radius: 50%;
  cursor: pointer;
}

/* appearance for checked radiobutton */
.quiz_radio-button input[type="radio"]:checked {
  background-color: #40539D;
  border: 2px solid #40539D;
}

/* Style for Correct Answer */
.quiz_radio-button input[type="radio"].approved {
  background-color: #11af5e;
  border: 2px solid #11af5e;
}

.quiz_radio-button input[type=radio].approved ~ label{
  color: #11af5e;
  font-style: italic;
}

/* Style for Incorrect Answer */
.quiz_radio-button input[type="radio"].incorrect {
  background-color: #791E1E;
  border: 2px solid #791E1E;
}

.quiz_radio-button input[type=radio].incorrect ~ label{
  color: #791E1E;
  font-style: italic;
}

/* Button-Quiz */
.button-quiz {
  border-radius: 50px;
  padding: 1em 3em;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  margin: 2em auto;
  background-color: #F2AF2B;
  border: 1px solid #F2AF2B;
  color: #FFF;
  font-weight: bold;
  cursor: pointer;
}

.button-quiz:hover {
  background-color: #b3bcbf;
  border: 1px solid #b3bcbf;
}

.button-quiz:disabled, .button-quiz:disabled:hover {
  background-color: #DDD;
  border: 1px solid #DDD;
  cursor: not-allowed;
}


.button-quiz-back {
  border-radius: 50px;
  padding: 1em 3em;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  margin: 0.5em;
  background-color: #FFF;
  border: 1px solid #DDD;
  color: #2CAA57;
  font-weight: bold;
  cursor: pointer;
}

.button-quiz-back:hover {
  color: #b3bcbf;
  border: 1px solid #b3bcbf;
}





/* MQ 750px */

@media screen and (max-width: 750px) { 
  .wrapper-iframe-form {
      margin: auto;
  }
}

/* MQ 500px */
@media screen and (max-width: 500px) { 
  .quiz_radio-button label {width: 85%;}
}

/* MQ 450px */
@media screen and (max-width: 450px) { 
  .quiz_radio-button label {width: 75%;}
}

/*******************************/

.status-time-test {
  margin-top: -25px;
  font-size: 12px;
  font-weight: 400;
  text-align: right;
  padding: 0.5em 1em;    
}

.status-time-test--process {
  background: #D2E7DD;
  border: 1px solid #BBDCCC;
  color: #155232;
}

.status-time-test--pending {
  background: #FEF4CE;
  border: 1px solid #FDEDB6;
  color: #927312;
}

.status-time-test--alert {
  background: #F7D7DA;
  border: 1px solid #F4C4C9;
  color: #811E2A;
}
.wrapper-menu {
  width: 135px;
  background-color: #5A93B6;
  position: fixed;
  height: 100%;
  top: 58px;
  z-index: 2;
}

/****************************/

.navigation-menu {
  text-align: center;
  color: #FFF;
  font-size: 13px;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 12px;
}

.navigation-menu li {
  padding: 0.5em;
  display: block;
}

.navigation-menu li a {
  color: #FFF;
  text-decoration: none;
  cursor: pointer;
}

.navigation-menu li img {
  display: block;
  margin: auto;
  width: 30%;
  height: auto;
  vertical-align: top;
}

span.lesson-mark {display: none;}
.navigation-menu li span.lesson-mark img {display: none;}

.navigation-menu li.active span.lesson-mark img {
  float: right;
  margin-top: -45px;
  margin-right: -20px;
  width: 39px;
  /* display: block; */
  background-color: transparent;
}

.navigation-menu li a:hover img { 
background-color: #567B91;
border-radius: 6px;
}

.navigation-menu li.active img, .navigation-menu li.active:hover img {
  background-color: #27466C;
  border-radius: 6px;
}

.navigation-menu li.active a {
  color: #27466C;
  font-weight: bold;
  font-size: 12px;
}

li.highlight-item a {
  color: #FFF700;
}


/* MQ 750px */

@media screen and (max-width: 750px) {
  .wrapper-menu {
      width: 100%;
      height: 80px;
      bottom: 12px /* 28 change for delete txt*/;
      top: auto;
  }

  .navigation-menu li img {
      width: 40px;
      height: 40px;
  }

  .navigation-menu {
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
        flex-grow: 1;
        align-items: stretch;
  }

  .navigation-menu li {
      width: 100%;
      text-align: center;
      padding: 0.25em 0 0.6em 0;
  }

  .navigation-menu li.active span.lesson-mark img {
      display: none;
  }

  .navigation-menu li.active {
    background-color: #27466C;
  }

  .navigation-menu li.active a {
    color: #FFF;
  }
}

/* MQ 500px */

@media screen and (max-width: 500px) {
  .wrapper-menu {
    height: 75px /* 78 change for delete txt*/;
  }

  .navigation-menu {
      font-size: 11px;
      letter-spacing: 0.3px;
  }

  .navigation-menu li.active a {
    font-size: 11px;
  }

  .navigation-menu li.active span.lesson-mark img {
      margin-top: 0;
  }
}

/* MQ 480px */

@media screen and (max-width: 480px) {
.wrapper-menu {
  bottom: 15px /* 21 change for delete txt*/;
  }

  .navigation-menu .navigation-menu--item {
    display: none;
  }

  .navigation-menu li img {
    width: 35px;
  }
}


.wrapper-pareo {
  width: 80%;
  max-width: 670px;
  margin: 0 0 0 10%;
  padding: 0 1.5em 0.5em 1.5em;
  color: #2C3140;
}

.wrapper-pareo .content-response-instruction {
  border-top: 1px solid #d8d8d8;
  margin-top: 70px;
}

.wrapper-pareo .content-response-instruction {
  border-top: 1px solid #d8d8d8;
  margin-top: 70px;
}


.wrapper-pareo p {
  line-height: 1.5em;
  font-size: 18px;
  margin-bottom: 0;
  margin: 0.5em 0;
}

/* .wrapper-pareo h1 {
  border-bottom: 1px solid #E7E7E7;
  padding-bottom: 0.25em;
  color: #40539D;
  margin: 1em 0 1.5em 0;    
} */


.content-pareo {
  margin-bottom: 1em; 
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
}

.content-pareo .pareo-col-left {
background-color: #F7F7F7;
width: 50%;
}

.content-pareo .pareo-col-right {
background-color: #E6EBFF;
width: 45%;
}

.pareo-col-left .list-form, .pareo-col-right .list-question {
list-style: none;
margin: 0;
padding: 0
}

.pareo-col-left .list-form li {
padding: 1em 1em 0.25em 1em;
}

/* .pareo-col-right .list-question li {
padding: 1em;
font-weight: bold;
} */

.pareo-col-right .list-question li {
  padding: 0.25em 1em;
}
  
.pareo-col-right .list-question li span {
  font-weight: bold;
  display: inline-block;
}

.pareo-col-right .list-question li img,
.pareo-col-left .list-form li img {
  width: 100%;
}


.pareo-col-left .list-form span {
padding-left: 0.5em;
}




.list-form input {
  max-width: 25%;
  border: 0;
  padding: 5px 10px;
  background: #F7F7F7 no-repeat;
  font-family: 'Roboto' , Arial , Helvetica , sans-serif;
  font-size: 18px;
  
  background-image: linear-gradient(to bottom, #7C89BF, #40539D), linear-gradient(to bottom, #DDD, #DDD);
  /* sizes for the 2 images (default state) */
  background-size: 0 2px, 100% 1px;
  /* positions for the 2 images. Change both "50%" to "0%" or "100%" and tri again */
  background-position: 50% 100%, 50% 100%;
  /* animation solely on background-size */
  transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
}

.list-form input:focus {
/* sizes for the 2 images (focus state) */
  background-size: 100% 2px, 100% 1px;
  outline: none;
}

.list-form input::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
color: #798EAF;
font-style: italic;
font-size: 16px;
opacity: 1; /* Firefox */
}

.list-form input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
color: #798EAF;
font-style: italic;
font-size: 16px;
opacity: 1; /* Firefox */
}

.list-form input:-ms-input-placeholder { /* Internet Explorer 10-11 */
color: #798EAF;
font-size: 16px;
font-style: italic;
}

.list-form input::-ms-input-placeholder { /* Microsoft Edge */
color: #798EAF;
font-size: 16px;
font-style: italic;
}


/* MQ 750px */

@media screen and (max-width: 750px) { 
  .content-pareo .pareo-col-left {
    width: 100%;
  }

.content-pareo .pareo-col-right {
    width: 100%;
    margin-top: 1.5em;
  }

  .pareo-col-right .list-question li img {
    width: auto;
  }
}

.container-modal {
  width: 80%;
  max-width: 550px;
  background-color: #FFF;
  margin: 2em auto;
  padding: 1em;
  box-shadow: 0 2px 7px 0 rgba(0,0,0,0.26);
  border-radius: 15px;
}

/****************************/

.container-modal-body {
  padding: 1em; 
      display: flex;
      flex-wrap: wrap;
  align-items: center;

      justify-content: space-between;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
}


.modal-body_two-col {
width: 48%;
  text-align: center;
}

h1.title-modal {
  margin: 0;
  font-size: 22px;
  padding: 0 1em;
}

h1.result-modal {
  font-size: 50px;
}

h1.result-modal span {
  font-size: 14px;
  font-weight: normal;
  display: block;
}

.modal-footer {
  padding: 1em;
}

.button-modal-primary {
  border-radius: 50px;
  padding: 1em 1.5em;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  margin: 0.5em;
  background-color: #F2AF2B;
  border: 1px solid #F2AF2B;
  color: #FFF;
  cursor: pointer;
}

.button-modal-primary:hover {
  background-color: #b3bcbf;
  border: 1px solid #b3bcbf;
}

.button-modal-secondary {
  border-radius: 50px;
  padding: 1em 1.5em;
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  margin: 0.5em;
  background-color: #FFF;
  border: 1px solid #2CAA57;
  color: #222;
  cursor: pointer;
}

.button-modal-secondary:hover {
  color: #b3bcbf;
  border: 1px solid #b3bcbf;
}

/* Progress circle */


.c100 .bar {
position: absolute;
border: 0.08em solid #7EC766;
width: 0.84em;
height: 0.84em;
clip: rect(0em, 0.5em, 1em, 0em);
border-radius: 50%;
-webkit-transform: rotate(0deg);
transform: rotate(0deg);
}

.c100 {
position: relative;
font-size: 120px;
width: 1em;
height: 1em;
border-radius: 50%;
/*float: left;*/
margin: auto; /*0 0.1em 0.1em 0;*/
background-color: #cccccc;
}

.c100 *,
.c100 *:before,
.c100 *:after {
box-sizing: content-box;
}

.c100.big {
font-size: 200px;
}

.c100 > span {
position: absolute;
width: 100%;
z-index: 1;
left: 0;
top: 0;
width: 5em;
line-height: 5em;
font-size: 0.2em;
color: #cccccc;
display: block;
text-align: center;
white-space: nowrap;
transition-property: all;
transition-duration: 0.2s;
transition-timing-function: ease-out;
}

.c100:after {
position: absolute;
top: 0.08em;
left: 0.08em;
display: block;
content: " ";
border-radius: 50%;
background-color: #FFF;
width: 0.84em;
height: 0.84em;
transition-property: all;
transition-duration: 0.2s;
transition-timing-function: ease-in;
}

.c100 .slice {
position: absolute;
width: 1em;
height: 1em;
clip: rect(0em, 1em, 1em, 0.5em);
}

.c100.p50 .bar {
-webkit-transform: rotate(180deg);
transform: rotate(180deg);
}

.c100:hover {
cursor: default;
}

.c100:hover > span {
color: #7EC766;
}

.c100:hover:after {
top: 0.04em;
left: 0.04em;
width: 0.92em;
height: 0.92em;
}


/* Close Windows */

button.close-windows { 
  padding: 0 0.5em 0.5em 0.5em; 
  border: 0;
  color: #898D93;
  background-color: transparent;
  font-size: 18px;
  cursor: pointer;
}

button.close-windows:hover { 
  color: #b3bcbf;
}

.modal-text-right {text-align: right;}



/* MQ 500px */

@media screen and (max-width: 500px) {
  .modal-body_two-col {
      width: 100%;
  }

  .modal-footer {
      text-align: center;
  }
}

/****************************************************************
 * CSS Percentage Circle
*****************************************************************/
.rect-auto, .c100.p51 .slice, .c100.p52 .slice, .c100.p53 .slice, .c100.p54 .slice, .c100.p55 .slice, .c100.p56 .slice, .c100.p57 .slice, .c100.p58 .slice, .c100.p59 .slice, .c100.p60 .slice, .c100.p61 .slice, .c100.p62 .slice, .c100.p63 .slice, .c100.p64 .slice, .c100.p65 .slice, .c100.p66 .slice, .c100.p67 .slice, .c100.p68 .slice, .c100.p69 .slice, .c100.p70 .slice, .c100.p71 .slice, .c100.p72 .slice, .c100.p73 .slice, .c100.p74 .slice, .c100.p75 .slice, .c100.p76 .slice, .c100.p77 .slice, .c100.p78 .slice, .c100.p79 .slice, .c100.p80 .slice, .c100.p81 .slice, .c100.p82 .slice, .c100.p83 .slice, .c100.p84 .slice, .c100.p85 .slice, .c100.p86 .slice, .c100.p87 .slice, .c100.p88 .slice, .c100.p89 .slice, .c100.p90 .slice, .c100.p91 .slice, .c100.p92 .slice, .c100.p93 .slice, .c100.p94 .slice, .c100.p95 .slice, .c100.p96 .slice, .c100.p97 .slice, .c100.p98 .slice, .c100.p99 .slice, .c100.p100 .slice {
  clip: rect(auto, auto, auto, auto);
}

.pie, .c100 .bar, .c100.p51 .fill, .c100.p52 .fill, .c100.p53 .fill, .c100.p54 .fill, .c100.p55 .fill, .c100.p56 .fill, .c100.p57 .fill, .c100.p58 .fill, .c100.p59 .fill, .c100.p60 .fill, .c100.p61 .fill, .c100.p62 .fill, .c100.p63 .fill, .c100.p64 .fill, .c100.p65 .fill, .c100.p66 .fill, .c100.p67 .fill, .c100.p68 .fill, .c100.p69 .fill, .c100.p70 .fill, .c100.p71 .fill, .c100.p72 .fill, .c100.p73 .fill, .c100.p74 .fill, .c100.p75 .fill, .c100.p76 .fill, .c100.p77 .fill, .c100.p78 .fill, .c100.p79 .fill, .c100.p80 .fill, .c100.p81 .fill, .c100.p82 .fill, .c100.p83 .fill, .c100.p84 .fill, .c100.p85 .fill, .c100.p86 .fill, .c100.p87 .fill, .c100.p88 .fill, .c100.p89 .fill, .c100.p90 .fill, .c100.p91 .fill, .c100.p92 .fill, .c100.p93 .fill, .c100.p94 .fill, .c100.p95 .fill, .c100.p96 .fill, .c100.p97 .fill, .c100.p98 .fill, .c100.p99 .fill, .c100.p100 .fill {
  position: absolute;
  border: 0.08em solid #7EC766;
  width: 0.84em;
  height: 0.84em;
  clip: rect(0em, 0.5em, 1em, 0em);
  border-radius: 50%;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.pie-fill, .c100.p51 .bar:after, .c100.p51 .fill, .c100.p52 .bar:after, .c100.p52 .fill, .c100.p53 .bar:after, .c100.p53 .fill, .c100.p54 .bar:after, .c100.p54 .fill, .c100.p55 .bar:after, .c100.p55 .fill, .c100.p56 .bar:after, .c100.p56 .fill, .c100.p57 .bar:after, .c100.p57 .fill, .c100.p58 .bar:after, .c100.p58 .fill, .c100.p59 .bar:after, .c100.p59 .fill, .c100.p60 .bar:after, .c100.p60 .fill, .c100.p61 .bar:after, .c100.p61 .fill, .c100.p62 .bar:after, .c100.p62 .fill, .c100.p63 .bar:after, .c100.p63 .fill, .c100.p64 .bar:after, .c100.p64 .fill, .c100.p65 .bar:after, .c100.p65 .fill, .c100.p66 .bar:after, .c100.p66 .fill, .c100.p67 .bar:after, .c100.p67 .fill, .c100.p68 .bar:after, .c100.p68 .fill, .c100.p69 .bar:after, .c100.p69 .fill, .c100.p70 .bar:after, .c100.p70 .fill, .c100.p71 .bar:after, .c100.p71 .fill, .c100.p72 .bar:after, .c100.p72 .fill, .c100.p73 .bar:after, .c100.p73 .fill, .c100.p74 .bar:after, .c100.p74 .fill, .c100.p75 .bar:after, .c100.p75 .fill, .c100.p76 .bar:after, .c100.p76 .fill, .c100.p77 .bar:after, .c100.p77 .fill, .c100.p78 .bar:after, .c100.p78 .fill, .c100.p79 .bar:after, .c100.p79 .fill, .c100.p80 .bar:after, .c100.p80 .fill, .c100.p81 .bar:after, .c100.p81 .fill, .c100.p82 .bar:after, .c100.p82 .fill, .c100.p83 .bar:after, .c100.p83 .fill, .c100.p84 .bar:after, .c100.p84 .fill, .c100.p85 .bar:after, .c100.p85 .fill, .c100.p86 .bar:after, .c100.p86 .fill, .c100.p87 .bar:after, .c100.p87 .fill, .c100.p88 .bar:after, .c100.p88 .fill, .c100.p89 .bar:after, .c100.p89 .fill, .c100.p90 .bar:after, .c100.p90 .fill, .c100.p91 .bar:after, .c100.p91 .fill, .c100.p92 .bar:after, .c100.p92 .fill, .c100.p93 .bar:after, .c100.p93 .fill, .c100.p94 .bar:after, .c100.p94 .fill, .c100.p95 .bar:after, .c100.p95 .fill, .c100.p96 .bar:after, .c100.p96 .fill, .c100.p97 .bar:after, .c100.p97 .fill, .c100.p98 .bar:after, .c100.p98 .fill, .c100.p99 .bar:after, .c100.p99 .fill, .c100.p100 .bar:after, .c100.p100 .fill {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.c100 {
  position: relative;
  font-size: 200px;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  /*float: left;
  margin: 0 0.1em 0.1em 0;*/
  background-color: #cccccc;
}
.c100 *, .c100 *:before, .c100 *:after {
  box-sizing: content-box;
}
.c100.center {
  float: none;
  margin: 0 auto;
}
.c100.big {
  font-size: 240px;
}
.c100.small {
  font-size: 80px;
}
.c100 > span {
  position: absolute;
  width: 100%;
  z-index: 1;
  left: 0;
  top: 0;
  width: 5em;
  line-height: 5em;
  font-size: 0.2em;
  color: #cccccc;
  display: block;
  text-align: center;
  white-space: nowrap;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
.c100:after {
  position: absolute;
  top: 0.08em;
  left: 0.08em;
  display: block;
  content: " ";
  border-radius: 50%;
  background-color: white;
  width: 0.84em;
  height: 0.84em;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
}
.c100 .slice {
  position: absolute;
  width: 1em;
  height: 1em;
  clip: rect(0em, 1em, 1em, 0.5em);
}
.c100.p1 .bar {
  -webkit-transform: rotate(3.6deg);
  transform: rotate(3.6deg);
}
.c100.p2 .bar {
  -webkit-transform: rotate(7.2deg);
  transform: rotate(7.2deg);
}
.c100.p3 .bar {
  -webkit-transform: rotate(10.8deg);
  transform: rotate(10.8deg);
}
.c100.p4 .bar {
  -webkit-transform: rotate(14.4deg);
  transform: rotate(14.4deg);
}
.c100.p5 .bar {
  -webkit-transform: rotate(18deg);
  transform: rotate(18deg);
}
.c100.p6 .bar {
  -webkit-transform: rotate(21.6deg);
  transform: rotate(21.6deg);
}
.c100.p7 .bar {
  -webkit-transform: rotate(25.2deg);
  transform: rotate(25.2deg);
}
.c100.p8 .bar {
  -webkit-transform: rotate(28.8deg);
  transform: rotate(28.8deg);
}
.c100.p9 .bar {
  -webkit-transform: rotate(32.4deg);
  transform: rotate(32.4deg);
}
.c100.p10 .bar {
  -webkit-transform: rotate(36deg);
  transform: rotate(36deg);
}
.c100.p11 .bar {
  -webkit-transform: rotate(39.6deg);
  transform: rotate(39.6deg);
}
.c100.p12 .bar {
  -webkit-transform: rotate(43.2deg);
  transform: rotate(43.2deg);
}
.c100.p13 .bar {
  -webkit-transform: rotate(46.8deg);
  transform: rotate(46.8deg);
}
.c100.p14 .bar {
  -webkit-transform: rotate(50.4deg);
  transform: rotate(50.4deg);
}
.c100.p15 .bar {
  -webkit-transform: rotate(54deg);
  transform: rotate(54deg);
}
.c100.p16 .bar {
  -webkit-transform: rotate(57.6deg);
  transform: rotate(57.6deg);
}
.c100.p17 .bar {
  -webkit-transform: rotate(61.2deg);
  transform: rotate(61.2deg);
}
.c100.p18 .bar {
  -webkit-transform: rotate(64.8deg);
  transform: rotate(64.8deg);
}
.c100.p19 .bar {
  -webkit-transform: rotate(68.4deg);
  transform: rotate(68.4deg);
}
.c100.p20 .bar {
  -webkit-transform: rotate(72deg);
  transform: rotate(72deg);
}
.c100.p21 .bar {
  -webkit-transform: rotate(75.6deg);
  transform: rotate(75.6deg);
}
.c100.p22 .bar {
  -webkit-transform: rotate(79.2deg);
  transform: rotate(79.2deg);
}
.c100.p23 .bar {
  -webkit-transform: rotate(82.8deg);
  transform: rotate(82.8deg);
}
.c100.p24 .bar {
  -webkit-transform: rotate(86.4deg);
  transform: rotate(86.4deg);
}
.c100.p25 .bar {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.c100.p26 .bar {
  -webkit-transform: rotate(93.6deg);
  transform: rotate(93.6deg);
}
.c100.p27 .bar {
  -webkit-transform: rotate(97.2deg);
  transform: rotate(97.2deg);
}
.c100.p28 .bar {
  -webkit-transform: rotate(100.8deg);
  transform: rotate(100.8deg);
}
.c100.p29 .bar {
  -webkit-transform: rotate(104.4deg);
  transform: rotate(104.4deg);
}
.c100.p30 .bar {
  -webkit-transform: rotate(108deg);
  transform: rotate(108deg);
}
.c100.p31 .bar {
  -webkit-transform: rotate(111.6deg);
  transform: rotate(111.6deg);
}
.c100.p32 .bar {
  -webkit-transform: rotate(115.2deg);
  transform: rotate(115.2deg);
}
.c100.p33 .bar {
  -webkit-transform: rotate(118.8deg);
  transform: rotate(118.8deg);
}
.c100.p34 .bar {
  -webkit-transform: rotate(122.4deg);
  transform: rotate(122.4deg);
}
.c100.p35 .bar {
  -webkit-transform: rotate(126deg);
  transform: rotate(126deg);
}
.c100.p36 .bar {
  -webkit-transform: rotate(129.6deg);
  transform: rotate(129.6deg);
}
.c100.p37 .bar {
  -webkit-transform: rotate(133.2deg);
  transform: rotate(133.2deg);
}
.c100.p38 .bar {
  -webkit-transform: rotate(136.8deg);
  transform: rotate(136.8deg);
}
.c100.p39 .bar {
  -webkit-transform: rotate(140.4deg);
  transform: rotate(140.4deg);
}
.c100.p40 .bar {
  -webkit-transform: rotate(144deg);
  transform: rotate(144deg);
}
.c100.p41 .bar {
  -webkit-transform: rotate(147.6deg);
  transform: rotate(147.6deg);
}
.c100.p42 .bar {
  -webkit-transform: rotate(151.2deg);
  transform: rotate(151.2deg);
}
.c100.p43 .bar {
  -webkit-transform: rotate(154.8deg);
  transform: rotate(154.8deg);
}
.c100.p44 .bar {
  -webkit-transform: rotate(158.4deg);
  transform: rotate(158.4deg);
}
.c100.p45 .bar {
  -webkit-transform: rotate(162deg);
  transform: rotate(162deg);
}
.c100.p46 .bar {
  -webkit-transform: rotate(165.6deg);
  transform: rotate(165.6deg);
}
.c100.p47 .bar {
  -webkit-transform: rotate(169.2deg);
  transform: rotate(169.2deg);
}
.c100.p48 .bar {
  -webkit-transform: rotate(172.8deg);
  transform: rotate(172.8deg);
}
.c100.p49 .bar {
  -webkit-transform: rotate(176.4deg);
  transform: rotate(176.4deg);
}
.c100.p50 .bar {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.c100.p51 .bar {
  -webkit-transform: rotate(183.6deg);
  transform: rotate(183.6deg);
}
.c100.p52 .bar {
  -webkit-transform: rotate(187.2deg);
  transform: rotate(187.2deg);
}
.c100.p53 .bar {
  -webkit-transform: rotate(190.8deg);
  transform: rotate(190.8deg);
}
.c100.p54 .bar {
  -webkit-transform: rotate(194.4deg);
  transform: rotate(194.4deg);
}
.c100.p55 .bar {
  -webkit-transform: rotate(198deg);
  transform: rotate(198deg);
}
.c100.p56 .bar {
  -webkit-transform: rotate(201.6deg);
  transform: rotate(201.6deg);
}
.c100.p57 .bar {
  -webkit-transform: rotate(205.2deg);
  transform: rotate(205.2deg);
}
.c100.p58 .bar {
  -webkit-transform: rotate(208.8deg);
  transform: rotate(208.8deg);
}
.c100.p59 .bar {
  -webkit-transform: rotate(212.4deg);
  transform: rotate(212.4deg);
}
.c100.p60 .bar {
  -webkit-transform: rotate(216deg);
  transform: rotate(216deg);
}
.c100.p61 .bar {
  -webkit-transform: rotate(219.6deg);
  transform: rotate(219.6deg);
}
.c100.p62 .bar {
  -webkit-transform: rotate(223.2deg);
  transform: rotate(223.2deg);
}
.c100.p63 .bar {
  -webkit-transform: rotate(226.8deg);
  transform: rotate(226.8deg);
}
.c100.p64 .bar {
  -webkit-transform: rotate(230.4deg);
  transform: rotate(230.4deg);
}
.c100.p65 .bar {
  -webkit-transform: rotate(234deg);
  transform: rotate(234deg);
}
.c100.p66 .bar {
  -webkit-transform: rotate(237.6deg);
  transform: rotate(237.6deg);
}
.c100.p67 .bar {
  -webkit-transform: rotate(241.2deg);
  transform: rotate(241.2deg);
}
.c100.p68 .bar {
  -webkit-transform: rotate(244.8deg);
  transform: rotate(244.8deg);
}
.c100.p69 .bar {
  -webkit-transform: rotate(248.4deg);
  transform: rotate(248.4deg);
}
.c100.p70 .bar {
  -webkit-transform: rotate(252deg);
  transform: rotate(252deg);
}
.c100.p71 .bar {
  -webkit-transform: rotate(255.6deg);
  transform: rotate(255.6deg);
}
.c100.p72 .bar {
  -webkit-transform: rotate(259.2deg);
  transform: rotate(259.2deg);
}
.c100.p73 .bar {
  -webkit-transform: rotate(262.8deg);
  transform: rotate(262.8deg);
}
.c100.p74 .bar {
  -webkit-transform: rotate(266.4deg);
  transform: rotate(266.4deg);
}
.c100.p75 .bar {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.c100.p76 .bar {
  -webkit-transform: rotate(273.6deg);
  transform: rotate(273.6deg);
}
.c100.p77 .bar {
  -webkit-transform: rotate(277.2deg);
  transform: rotate(277.2deg);
}
.c100.p78 .bar {
  -webkit-transform: rotate(280.8deg);
  transform: rotate(280.8deg);
}
.c100.p79 .bar {
  -webkit-transform: rotate(284.4deg);
  transform: rotate(284.4deg);
}
.c100.p80 .bar {
  -webkit-transform: rotate(288deg);
  transform: rotate(288deg);
}
.c100.p81 .bar {
  -webkit-transform: rotate(291.6deg);
  transform: rotate(291.6deg);
}
.c100.p82 .bar {
  -webkit-transform: rotate(295.2deg);
  transform: rotate(295.2deg);
}
.c100.p83 .bar {
  -webkit-transform: rotate(298.8deg);
  transform: rotate(298.8deg);
}
.c100.p84 .bar {
  -webkit-transform: rotate(302.4deg);
  transform: rotate(302.4deg);
}
.c100.p85 .bar {
  -webkit-transform: rotate(306deg);
  transform: rotate(306deg);
}
.c100.p86 .bar {
  -webkit-transform: rotate(309.6deg);
  transform: rotate(309.6deg);
}
.c100.p87 .bar {
  -webkit-transform: rotate(313.2deg);
  transform: rotate(313.2deg);
}
.c100.p88 .bar {
  -webkit-transform: rotate(316.8deg);
  transform: rotate(316.8deg);
}
.c100.p89 .bar {
  -webkit-transform: rotate(320.4deg);
  transform: rotate(320.4deg);
}
.c100.p90 .bar {
  -webkit-transform: rotate(324deg);
  transform: rotate(324deg);
}
.c100.p91 .bar {
  -webkit-transform: rotate(327.6deg);
  transform: rotate(327.6deg);
}
.c100.p92 .bar {
  -webkit-transform: rotate(331.2deg);
  transform: rotate(331.2deg);
}
.c100.p93 .bar {
  -webkit-transform: rotate(334.8deg);
  transform: rotate(334.8deg);
}
.c100.p94 .bar {
  -webkit-transform: rotate(338.4deg);
  transform: rotate(338.4deg);
}
.c100.p95 .bar {
  -webkit-transform: rotate(342deg);
  transform: rotate(342deg);
}
.c100.p96 .bar {
  -webkit-transform: rotate(345.6deg);
  transform: rotate(345.6deg);
}
.c100.p97 .bar {
  -webkit-transform: rotate(349.2deg);
  transform: rotate(349.2deg);
}
.c100.p98 .bar {
  -webkit-transform: rotate(352.8deg);
  transform: rotate(352.8deg);
}
.c100.p99 .bar {
  -webkit-transform: rotate(356.4deg);
  transform: rotate(356.4deg);
}
.c100.p100 .bar {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.c100:hover {
  cursor: default;
}
.c100:hover > span {
  color: #7EC766;
}

.c100:hover:after {
  top: 0.04em;
  left: 0.04em;
  width: 0.92em;
  height: 0.92em;
}

.modal-timesOut-body,
.modal-timesOut-footer {
    text-align: center;
}

.modal-timesOut-body--image {
    display: block;
    width: 100%;
}

.modal-timesOut-body--title {
    text-align: center;
    width: 100%;
    margin: 0;
    margin-top: .8em;
}

.modal-timesOut-body p {
    text-align: center;
    width: 100%;
}
.container-message {
    width: 100%;
    text-align: center;
}

.icon-message-test {
   color: #80C968;
   font-size: 80px; 
}


.wrapper-iframe-form {
  width: 100%;
  max-width: 720px;
  margin: -35px 0 0 10%;
  padding: 0 1.5em 0.5em 1.5em;
  color: #2C3140;
}

.wrapper-iframe-form span {
  line-height: 1.5em;
  font-size: 18px;
  margin-bottom: 0;
  margin: 0.5em 0;
}

.wrapper-iframe-form h1, .wrapper-iframe-form h2, .wrapper-iframe-form h3 {
  border-bottom: 1px solid #E7E7E7;
  padding-bottom: 0.25em;    
}

.wrapper-iframe-form h1, 
.wrapper-iframe-form h2,
.wrapper-iframe-form h3,
.wrapper-iframe-form h4 {
  color: #40539D;
  margin: 1em 0 1.5em 0;
}

.instruction {
  font-style: italic;
  font-size: 15px;
  color: #666;
  padding-bottom: 1em;
}

.question, .quiz-title {
  /* font-weight: bold; */
  color: #000;
  padding-bottom: 1em;
  font-size: 20px;
}

h4.title-page {
  background-color: #e8eff3;
  font-size: 16px;
  padding: 0.7em;
  color: #253084;
}

.wrapper-iframe-form ul {
  margin: 2em 0;
}

.wrapper-iframe-form li {
  margin-bottom: 0.5em;
}

.wrapper-iframe-form a {
  color: #DC8520;
  text-decoration: underline;
}

.wrapper-iframe-form a:hover {
  text-decoration: none;
}


.wrapper-iframe-form img {
  display: block;
  margin: 1em 0;
}

.content-form {
  background-color: #F7F7F7;
  padding: 1em;
  margin-bottom: 1em;
}

.wrapper-iframe-form textarea {
  display: block;
  width: 98%;
  border: 0;
  padding: 10px 5px;
  background: #F7F7F7 no-repeat;
  font-family: 'Roboto' , Arial , Helvetica , sans-serif;
  font-size: 18px;
  
  background-image: linear-gradient(to bottom, #7C89BF, #40539D), linear-gradient(to bottom, #DDD, #DDD);
  /* sizes for the 2 images (default state) */
  background-size: 0 2px, 100% 1px;
  /* positions for the 2 images. Change both "50%" to "0%" or "100%" and tri again */
  background-position: 50% 100%, 50% 100%;
  /* animation solely on background-size */
  transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
}

.wrapper-iframe-form input:focus,
.wrapper-iframe-form textarea:focus{
/* sizes for the 2 images (focus state) */
  background-size: 100% 2px, 100% 1px;
  outline: none;
}

.quiz_radio-button {
  display: block;
  padding-bottom: 0.2em;
}
.quiz_radio-button label, .quiz_radio-button input {
  display: inline-block; 
  vertical-align: top;
}
.quiz_radio-button label {width: 90%;}
.quiz_radio-button input {margin-right: 15px; }
.quiz_radio-button p, .quiz_radio-button img { margin: 0; padding: 0;}


.quiz_radio-button input[type=radio]:checked ~ label{
  color: #40539D;
  font-weight: bold;
}

.quiz_radio-button input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 25px;
  height: 25px;
  padding: 6px;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #CCC;
  background-color: #CCC;
  border-radius: 50%;
  cursor: pointer;
}

/* appearance for checked radiobutton */
.quiz_radio-button input[type="radio"]:checked {
  background-color: #40539D;
  border: 2px solid #40539D;
}

/* Style for Correct Answer */
.quiz_radio-button input[type="radio"].approved {
  background-color: #11af5e;
  border: 2px solid #11af5e;
}

.quiz_radio-button input[type=radio].approved ~ label{
  color: #11af5e;
  font-style: italic;
}

/* Style for Incorrect Answer */
.quiz_radio-button input[type="radio"].incorrect {
  background-color: #791E1E;
  border: 2px solid #791E1E;
}

.quiz_radio-button input[type=radio].incorrect ~ label{
  color: #791E1E;
  font-style: italic;
}

/* Button-Quiz */
.button-quiz {
  border-radius: 50px;
  padding: 1em 3em;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  margin: 2em auto;
  background-color: #F2AF2B;
  border: 1px solid #F2AF2B;
  color: #FFF;
  font-weight: bold;
  cursor: pointer;
}

.button-quiz:hover {
  background-color: #b3bcbf;
  border: 1px solid #b3bcbf;
}

.button-quiz:disabled, .button-quiz:disabled:hover {
  background-color: #DDD;
  border: 1px solid #DDD;
  cursor: not-allowed;
}


.button-quiz-back {
  border-radius: 50px;
  padding: 1em 3em;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
  margin: 0.5em;
  background-color: #FFF;
  border: 1px solid #DDD;
  color: #2CAA57;
  font-weight: bold;
  cursor: pointer;
}

.button-quiz-back:hover {
  color: #b3bcbf;
  border: 1px solid #b3bcbf;
}





/* MQ 750px */

@media screen and (max-width: 750px) { 
  .wrapper-iframe-form {
      margin: auto;
  }
}

/* MQ 500px */
@media screen and (max-width: 500px) { 
  .quiz_radio-button label {width: 85%;}
}

/* MQ 450px */
@media screen and (max-width: 450px) { 
  .quiz_radio-button label {width: 75%;}
}
.wrapper-menu {
  width: 135px;
  background-color: #5A93B6;
  position: fixed;
  height: 100%;
  top: 58px;
  z-index: 2;
}

/****************************/

.navigation-menu {
  text-align: center;
  color: #FFF;
  font-size: 13px;
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 12px;
}

.navigation-menu li {
  padding: 0.5em;
  display: block;
}

.navigation-menu li a {
  color: #FFF;
  text-decoration: none;
  cursor: pointer;
}

.navigation-menu li img {
  display: block;
  margin: auto;
  width: 30%;
  height: auto;
  vertical-align: top;
}

span.lesson-mark {display: none;}
.navigation-menu li span.lesson-mark img {display: none;}

.navigation-menu li.active span.lesson-mark img {
  float: right;
  margin-top: -45px;
  margin-right: -20px;
  width: 39px;
  /* display: block; */
  background-color: transparent;
}

.navigation-menu li a:hover img { 
background-color: #567B91;
border-radius: 6px;
}

.navigation-menu li.active img, .navigation-menu li.active:hover img {
  background-color: #27466C;
  border-radius: 6px;
}

.navigation-menu li.active a {
  color: #27466C;
  font-weight: bold;
  font-size: 12px;
}

li.highlight-item a {
  color: #FFF700;
}


/* MQ 750px */

@media screen and (max-width: 750px) {
  .wrapper-menu {
      width: 100%;
      height: 80px;
      bottom: 12px /* 28 change for delete txt*/;
      top: auto;
  }

  .navigation-menu li img {
      width: 40px;
      height: 40px;
  }

  .navigation-menu {
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
        flex-grow: 1;
        align-items: stretch;
  }

  .navigation-menu li {
      width: 100%;
      text-align: center;
      padding: 0.25em 0 0.6em 0;
  }

  .navigation-menu li.active span.lesson-mark img {
      display: none;
  }

  .navigation-menu li.active {
    background-color: #27466C;
  }

  .navigation-menu li.active a {
    color: #FFF;
  }
}

/* MQ 500px */

 @media screen and (max-width: 500px) {
  .wrapper-menu {
      height: 75px /* 78 change for delete txt*/;
  }

  .navigation-menu {
      font-size: 11px;
      letter-spacing: 0.3px;
  }

  .navigation-menu li.active a {
    font-size: 11px;
  }

  .navigation-menu li.active span.lesson-mark img {
      margin-top: 0;
  }
} 

/* MQ 480px */

@media screen and (max-width: 480px) {
.wrapper-menu {
  bottom: 15px /* 21 change for delete txt*/;
  }

  .navigation-menu .navigation-menu--item {
    display: none;
  }

  .navigation-menu li img {
    width: 35px;
  }
} 



/******/

.preview-docs-message {
  width: 100%;
  margin-top: 1.5em;
  display: flex;
flex-wrap: wrap;
    align-items: stretch;
        align-content: space-between
}

.preview-docs-message--seven-col {
  width: 12%;
  padding: 0.25em;
}

.preview-docs-message--seven-col img {
  width: 100%;
  height: 105px;
  object-fit: cover;
  border: 1px solid #DDD;
}

.preview-docs-message--seven-col p {
  font-size: 12px;
  white-space: nowrap; 
  width: 95%; 
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  margin: 0.25em 0;
  color: #525d79;
}

.preview-docs-message--seven-col p:hover {
  overflow: visible;
  white-space: nowrap;
}





/* MQ 750px */

@media screen and (max-width: 750px) {
  .preview-docs-message--seven-col {
    width: 20%;
    margin-bottom: 1em;
  }  
}

/* MQ 500px */

@media screen and (max-width: 500px) {
  .preview-docs-message--seven-col {
    width: 30%;
  }  
}


.wrapper-pareo {
  width: 80%;
  max-width: 670px;
  margin: 0 0 0 10%;
  padding: 0 1.5em 0.5em 1.5em;
  color: #2C3140;
}

.wrapper-pareo p {
  line-height: 1.5em;
  font-size: 18px;
  margin-bottom: 0;
  margin: 0.5em 0;
}

/* .wrapper-pareo h1 {
  border-bottom: 1px solid #E7E7E7;
  padding-bottom: 0.25em;
  color: #40539D;
  margin: 1em 0 1.5em 0;    
} */


.content-pareo {
  margin-bottom: 1em; 
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
}

.content-pareo .pareo-col-left {
background-color: #F7F7F7;
width: 50%;
}

.content-pareo .pareo-col-right {
background-color: #E6EBFF;
width: 45%;
}

.pareo-col-left .list-form, .pareo-col-right .list-question {
list-style: none;
margin: 0;
padding: 0
}

.pareo-col-left .list-form li {
padding: 1em 1em 0.25em 1em;
}

.pareo-col-right .list-question li {
  padding: 0.25em 1em;
}

.pareo-col-right .list-question li span {
  font-weight: bold;
  display: inline-block;
}

.pareo-col-right .list-question li span p {
  margin: 0;
}


.pareo-col-left .list-form span {
padding-left: 0.5em;
display: inline-block;
}

.pareo-col-left .list-form span p {
  margin: 0;
  }




.list-form input {
  max-width: 12%;
  border: 0;
  padding: 5px;
  background: #F7F7F7 no-repeat;
  font-family: 'Roboto' , Arial , Helvetica , sans-serif;
  font-size: 18px;
  
  background-image: linear-gradient(to bottom, #7C89BF, #40539D), linear-gradient(to bottom, #DDD, #DDD);
  /* sizes for the 2 images (default state) */
  background-size: 0 2px, 100% 1px;
  /* positions for the 2 images. Change both "50%" to "0%" or "100%" and tri again */
  background-position: 50% 100%, 50% 100%;
  /* animation solely on background-size */
  transition: background-size 0.3s cubic-bezier(0.64, 0.09, 0.08, 1);
}

.list-form input:focus {
/* sizes for the 2 images (focus state) */
  background-size: 100% 2px, 100% 1px;
  outline: none;
}

.list-form input::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
color: #798EAF;
font-style: italic;
font-size: 16px;
opacity: 1; /* Firefox */
}

.list-form input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
color: #798EAF;
font-style: italic;
font-size: 16px;
opacity: 1; /* Firefox */
}

.list-form input:-ms-input-placeholder { /* Internet Explorer 10-11 */
color: #798EAF;
font-size: 16px;
font-style: italic;
}

.list-form input::-ms-input-placeholder { /* Microsoft Edge */
color: #798EAF;
font-size: 16px;
font-style: italic;
}


/* MQ 750px */

@media screen and (max-width: 750px) { 
  .content-pareo .pareo-col-left {
    width: 100%;
  }

.content-pareo .pareo-col-right {
    width: 100%;
    margin-top: 1.5em;
  }
}


.wrapper-forum {
  width: 100%;
}

/*****************/

/* Table Forum */

table.content-table-forum {
border-collapse: collapse;
margin: 2em 0;
table-layout: auto;
width: 100%;
}

.content-table-forum thead {
  display: none;
}

.content-table-forum tr:nth-of-type(odd) {
text-align: left;
vertical-align: top;
color: #2A426D;
font-size: 13px;
}

.content-table-forum td:nth-child(1) {
  width: 35%;
  padding-right: 1em
}

.content-table-forum td:nth-child(1) p {
  margin: 0.2em 0;
}

.content-table-forum tr {
border-bottom: 1px solid #EBEBEB;
}

.content-table-forum thead tr {
border-bottom: 0;
}

.content-table-forum th {
padding-bottom: 1em;
}

.content-table-forum td {
padding: 1em 0;
font-size: 16px;
}

.content-table-forum .table-forum--description {
font-size: 13px;
color: #222;
}

.content-table-forum .table-forum--title {
font-weight: bold;
font-size: 17px;
color: #000;
}

.content-table-forum .table-forum--date {
color: #9098AC;
font-size: 13px;
}

.content-table-forum .table-forum--number {
  color: #E88B19;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}

.content-table-forum .table-forum--author{
font-size: 15px;
color: #222;
}

.content-table-forum .list-library__aditional {
  font-weight: bold;
}

/* MQ 750px */

@media screen and (max-width: 750px) {
table.content-table-forum .content-table-forumthead, 
.content-table-forum tbody, 
.content-table-forum th, 
.content-table-forum td, 
.content-table-forum tr { 
  display: block; 
}

.content-table-forum th {
  display: none;
}

.content-table-forum td {
  padding: 0.25em 0
}

.content-table-forum td:last-child {
  padding-bottom: 2em;
}

.content-table-forum td:nth-child(1) {
  width: auto;
  padding-right: 1em
} 
}


/* Forum Detail */

.wrapper-forum h1 {
font-weight: 800;
font-size: 20px;
margin: 1em 0 1em 0;
border-bottom: 1px solid #DDD;
padding-bottom: 0.5em;
}

.wrapper-forum h1 span {
  font-weight: normal;
}

.forum-description-detail__txt-secondary {
color: #9098AC;
margin: 0.25em 0;
font-size: 14px;
}

.content-forum__buttons {
  padding: 1.5em 0;
  text-align: right;
}

.content-forum__post {
background-color: #F7F7F7;
border: 1px solid #DFDFDF;
padding: 1em 1.5em 0.25em 1.5em;
margin-bottom: 0.8em; 
  display: flex;
flex-wrap: wrap;
justify-content: space-between;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
}

.post__description {
  width: 100%;
  padding: 1em 0;
  line-height: 1.4em;
  color: #333;
}

.post__author {
color: #222;
font-size: 15px;
font-weight: bold;
}

.post__date {
color: #9098AC;
font-size: 14px;
}

.content-forum__post--footer {
  border-top: 1px solid #DCDCDC;
  margin-top: 0.5em;
  padding-top: 0.5em;
  width: 100%;
  text-align: right;
}

.button-w-icon {
border: 0;
padding: 0.5em 0.8em;
font-size: 13px;
color: #27466D;
cursor: pointer;
background: transparent;
font-weight: bold;
}

.button-w-icon .fas {
font-size: 13px;
margin-right: 0.25em;
}

.button-w-icon:hover {
  color: #5A93B6;
}


.content-forum {
  max-width: 95%;
}

.content-forum .wrapper-col {
  padding: 0;
  justify-content: space-between;
}

.content-forum .general-module {
  padding: 0;
  margin: 0;
}

h1.title-modal-message__forum {
  margin-top: -20px;
}

.body-forum {
  padding-top: 1em;
}

.body-forum textarea {
  background-color: #f3f3f3;
}

/* MQ 750px */

@media screen and (max-width: 750px) {
  .content-forum {
    max-width: 100%;
  }
}


.wrapper-chat {
  width: 100%;
  max-width: 90%;
}

/*****************/

.wrapper-col-chat { 
    display: flex;
        /*-webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
  flex-wrap: wrap;*/
  position: relative;
  height: 80vh;

  }
  
  .col-chat__contacts {
  background-color: #798EB0;
  flex: 0 0 300px;
  /* border-bottom-left-radius: 15px;
  border-top-left-radius: 15px; */
  border: 1px solid #798EB0;
  position: relative;
  }
  
.col-chat__messages {
  flex: 1 1 auto;
  position: relative;
  background-image: url(/static/media/background-chat.2b08f0e9.jpg);
  /* border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
  border: 1px solid #798EB0; */
}
  
  /****/
  
.chat__headerTitle {
  /* border-bottom: 1px solid #96ABCD; */
  padding: 0.5em 1em;
  height: 50px;  
}

.col-chat__messages .chat__headerTitle {
  padding: 0.5em 1em;
  height: 60px;
  background-image: linear-gradient(#f1f1f1, #FFF);
  box-shadow: 0px 3px 7px -1px rgba(0,0,0,0.2);
    -webkit-box-shadow: 0px 3px 7px -1px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 3px 7px -1px rgba(0,0,0,0.2);
}
  
.chat__headerTitle--title {
  padding: 1.25em 1em;
  text-transform: uppercase;
  font-size: 14px;
  color: #FFF;
  letter-spacing: 3px
}
  
  
  /****/
  .chat__center-content {  }
  
  
  /*****/
  
  .col-chat__contacts__menu {
  background-color: #27466D;
  padding: 0.5em 1em;
  position: absolute;
  bottom: 0;
  width: 89.5%;
  /* border-bottom-left-radius: 15px; */
  margin: 0;
      display: flex;
      justify-content: space-between;
      -webkit-box-orient: horizontal;
  
              /* -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1; */
          align-items: stretch;
  }
  
  .contacts__menu--item {
  width: 50%;
  color: #798EB0;
  text-align: center;
  list-style: none;
  text-transform: uppercase;  
  }
  
  .contacts__menu--item a {
  color: #798EB0;
  text-decoration: none;
  }
  
  .contacts__menu--item a:hover {
  color: #5A93B7;
  }
  
  .contacts__menu--item.active a {
  color: #F2AF2B;
  }
  
  .contacts__menu--item__icon {
  font-size: 20px;
  margin: 0;
  }
  
  .contacts__menu--item__txt {
  font-size: 10px;
  margin: 0.5em 0 0 0;
  letter-spacing: 1px
  }
  
  
  /*****/
  
  .col-chat__messages__form {
    padding: 1em;
    text-align: center;
    display: flex;
    position: absolute;
    bottom: 0;
    width: 96.5%;
    background-color: #f1f1f1;
  }
  
  .col-chat__messages__form input {
    border: 1px solid #C9C9C9;
    padding: 1.5em 2em;
    font-size: 16px;
    box-sizing : border-box;
    width: 100%;
    border-radius: 50px;
    background-color: #FFF;
    color: #909090;
  }
   
  .col-chat__messages__form input::placeholder,
  .col-chat__messages__form input:-ms-input-placeholder,
  .col-chat__messages__form input::-ms-input-placeholder { 
    color: #909090;
    opacity: 1; /* Firefox */
  }
  
  .form__button--send {
    background-color: transparent;
    border: 0;
    padding: 0 0.5em;
    font-size: 24px;
    cursor: pointer;
    color: #798EB0
  }
  
  
  /****/
  
/* Special Style on Messages */
.messageBox {
  border-radius: 10px !important;
  padding: 10px 20px !important;
}

.sentText {
  letter-spacing: 0.5px;
  font-size: 13px;
}

  /****/
  
.chat__center-content__wrapper-mod-contacts {
  overflow: auto;
  padding: 1em;
  height: 66vh;
}

.chat__center-content__wrapper-mod-messages {
  overflow: auto;
  padding: 1em;
  height: 50vh;
}

.chat__center-content__wrapper-mod-contacts {
  scrollbar-width: thin;
  scrollbar-color: #798EB0 #6c81a5;
}

.chat__center-content__wrapper-mod-contacts::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
	border-radius: 10px;
	background-color: #798EB0;
}

.chat__center-content__wrapper-mod-contacts::-webkit-scrollbar {
	width: 10px;
	background-color: #798EB0;
}

.chat__center-content__wrapper-mod-contacts::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0);
	background-color: #6c81a5;
}
  
  
  /****/
  
  .mensaje-respuesta {
  background-color: #000;
  }
  
  .mensaje-respuesta__right {
  width: 20%;
  background-color: #FFF
  }
  
  
  
  /****/
  
  .chat__center-content__article {
  margin-bottom: 1em;
  padding-bottom: 1em;
  border-bottom: 1px dashed #96ABCD;
  cursor: pointer; 
    display: flex;
  flex-wrap: wrap;
  /*   -webkit-box-align: center;
    -ms-flex-align: center;
        align-items: center;*/
  }

.chat__center-content__article:hover .content__article__user__name {
  color: #27466C
}

.chat__center-content__article .content__article__user__name.active {
  color: #27466C
}
  
  .content__article__avatar {
  width: 15%;
  }
  
  .content__article__avatar--img {
  width: 100%;
  border-radius: 50%;
  border: solid 3px #B5DEE9;
  box-shadow: 0 0 0 3px #798EB0;
  background-image: linear-gradient(white, white);
  }
  
  .content__article__user {
  padding: 0.5em 1em;
  color: #FFF;
  font-size: 17px;
  width: 58%;  
  }
  
  .content__article__user__status {
  padding: 0;
  margin: 0.25em 0 0 0;
  font-size: 12px;
  }
  
  .content__article__user__status--ON {
  color: #7ED577;
  padding-right: 0.5em;
  }
  
  .content__article__user__status--OFF {
  color: #C3C3C3;
  padding-right: 0.5em;
  }
  
  .content__article__user__name {
  white-space: nowrap;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  max-width: 160px;
  content: "..."
  }
  
  .content__article__user__info {
  padding: 0;
  margin: 0.5em 0 0 0;
  font-size: 13px;
  color: #FFF;
  }
  
  .content__article__user__pointer {
  font-size: 12px;
  color: #00FFF3;
  padding: 1em 0;
  }
  
  /* ON TITLE COL MESANGGES */
  .col-chat__messages .chat__center-content__article {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
   align-items: center;
  }
  
  
  .col-chat__messages .content__article__avatar {
  width: 35px;
  height: 35px;
  }
  
  .col-chat__messages .content__article__avatar--img {
  box-shadow: 0 0 0 3px #FFF;
  }
  
  .col-chat__messages .content__article__user__name,
  .col-chat__messages .content__article__user__status {
  color: #909090;
  text-transform: none;
  }
  
  /* MQ MIN 650 */
  
  @media screen and (min-width: 650px) {
  .col-chat__messages .content__article__user__name {
    white-space: normal;
    max-width: 100%;
  }
  }
  
  .messageBox.backgroundLight,
  .messageBox.backgroundBlue {
    border: 1px solid #CACACA;
    margin-bottom: 0.5em;
  }
  
  .messageBox.backgroundLight {
    background-color: #E5E9F0;
  }
  
  .messageBox.backgroundBlue {
    background-color: #CED9EB;
  }
  
  .messageBox .colorWhite,
  .messageBox .colorDark {
    color: #222;
    font-size: 16px;
  }
  
  /* MQ 750 */
  @media screen and (max-width: 750px) {
    .col-chat__contacts__menu {
      background-color: #1F2F43;
    }
  }
  
  /* MQ 550 */
  
  @media screen and (max-width: 550px) {
    .wrapper-col-chat {
      flex-wrap: wrap;
    }
      
    .col-chat__contacts {
      background-color: #798EB0;
      flex: 0 0 100%;
    }

    .chat__center-content__wrapper-mod-contacts,
    .chat__center-content__wrapper-mod-messages {
      height: 20vh;
    }
  
    .chat__headerTitle {
      height: auto;  
    }
      
    .chat__headerTitle--title {
      padding: 0.25em 1em;
      font-size: 13px;
    }
  
    .col-chat__contacts,
    .col-chat__messages,
    .col-chat__contacts__menu {
      border-radius: 0;
    }

    .wrapper-chat { max-width: 98%;}

    .col-chat__messages__form { width: 94%;}
    .col-chat__contacts__menu {width: 93.7%;}
  
    .col-chat__contacts:nth-of-type(1) { order: 2 !important; }
    .col-chat__contacts:nth-of-type(2) { order: 1 !important; } 
  }
.messageBox {
  background: #F3F3F3;
  border-radius: 20px;
  padding: 5px 20px;
  color: white;
  display: inline-block;
  max-width: 80%;
}

.messageText {
  width: 100%;
  letter-spacing: 0;
  float: left;
  font-size: 1.1em;
  word-wrap: break-word;
}

.messageText img {
  vertical-align: middle;
}

.messageContainer {
  display: flex;
  justify-content: flex-end;
  padding: 0 5%;
  margin-top: 3px;
}

.sentText {
  display: flex;
  align-items: center;
  font-family: Helvetica;
  color: #828282;
  letter-spacing: 0.3px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.colorWhite {
  color: white;
}

.colorDark {
  color: #353535;
}

.backgroundBlue {
  background: #2979FF;
}

.backgroundLight {
  background: #F3F3F3;
}
.wrapper-not-authorized {
    background: url(/static/media/bg.75e92418.jpg) no-repeat center center fixed;
	height: 100vh;
	background-size: cover;
}
  
.not-authorized {
    color: #9A9A9A;
	font-family: 'Roboto', sans-serif;
	font-size: 16px;
	line-height: 1.4em;
	margin-top: 0px;
	font-weight: 300; 
}

#page {
	text-align: center;
}

#page img {
	width: 100%;
}

#page h1 img {
	width: auto;
}

#content {	
	width: 90%;
	margin: auto;	
	display: flex;
	flex-wrap: wrap;
}

.DosCol {
	width: 50%;
	margin-top: 50px;
}

/* 

	4.1. MEDIA QUERIES ESTRUCTURA GENERAL

*/


@media screen and (max-width: 830px){
	.DosCol {
		width: 100%;
		margin-top: 10px;
	}
}

/* 

	5. MÓDULOS DE CONTENIDO

*/

.mod-txt {
	padding: 1em;
	text-align: center;
}

.DosCol .mod-txt {
	text-align: left;
	padding: 3em;
}

.DosCol .mod-txt h1 {
	font-size: calc(60px + 0.3vw);
	font-weight: 900;
	line-height: 1.3em;
	color: #F47D45;
}

.DosCol .mod-txt h2 {
	font-size: calc(14px + 0.3vw);
    font-weight: 900;
    margin-bottom: 1.2em;
    text-transform: uppercase;
    color: #27466C;
}

.DosCol .mod-txt p {
	margin-top: 0.25em;
	font-size: 17px;
	font-weight: 400;
	line-height: 1.1em;
	color: #27466C;
}

@media(min-width: 1100px) {
    #page > img {
		width: 65%;
	}
}

@media(max-width: 830px) {
	.DosCol .mod-txt {
		text-align: center;
	}
}

@media(max-width: 430px) {
    .mod-txt h1 {
		font-size: 25px;
		line-height: 1.2em;
	}
}
.MuiButton-root.button-primary, .MuiButton-root.button-secondary {
    border-radius: 5px;
    padding: 0.8em 1.5em;
    font-size: 12px;
    text-transform: uppercase;
    color: #FFF;
    cursor: pointer;
}

.MuiButton-root.button-primary {
    background-color: #F2AF2B;
    border: 1px solid #F2AF2B;
}

.MuiButton-root.button-secondary {
    background-color: #27466C;
  border: 1px solid #27466C;
}

.MuiButton-root.button-primary:hover {
    background-color: #B3BCDF;
    border: 1px solid #B3BCDF;
}
  
.MuiButton-root.button-secondary:hover {
    background-color: #5A93B6;
    border: 1px solid #5A93B6;
}

.MuiDialogContent-root p {
    text-align: center;

}

.MuiDialogContent-root p .fas {    
    font-size: 35px;    
    color: #e49a9a;
    vertical-align: middle;
    margin-right: 0.25em;
}

.MuiDialogTitle-root h2 {
    text-align: center;
    color: #921111;
    font-weight: bold;
    font-size: 30px;
}

.MuiDialogTitle-root {
    padding: 25px 24px 0 24px !important;
}
.background-teams {
  margin: 0;
  padding: 0;
  background-color: #f3f2f1;
}

.microsoftLogo {
  padding: 1rem;
}

.header {
  display: flex;
  justify-content: space-around;
  padding: 1rem;
  border-bottom: 1px solid #cccccc6b;
}

.headerLogo {
  margin-right: 1rem;
  max-width: 32px;
  width: 32px;
}

.headerIcon {
  cursor: pointer;
  margin-left: auto;
  text-align: right;
}

.headerIcon span {
  display: inherit;
}

.headerIcon i {
  color: #3e3e3e;
  position: relative;
  top: 7px;
}

.container-teams {
  padding: 1rem 1.5rem 2rem 1.5rem;
  width: 100%;
}

.teamsButton,
.teamsButtonInverted {
  border-radius: 3px;
  height: 2.25rem;
  font-size: .875rem;
  font-weight: 600;
  padding: 0 1.25rem;
  text-decoration: none;
}

.teamsButton svg,
.teamsButtonInverted svg {
  margin-right: 0.5rem;
  font-size: .8rem;
}

.teamsButton {
  background-color: #6264a7;
  border: 1px solid hsla(0, 0%, 100%, .04);
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, .15);
}

.teamsButton:hover {
  background: #464775;
  border-color: transparent;
}

.teamsButton:active {
  background: #33344A;
  border-color: transparent;
}

.teamsButtonInverted {
  border: 1px solid #e3e5e9;
  background-color: #fff;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, .075);
}

.teamsButtonInverted:hover {
  background-color: #f3f2f1;
}

.teamsButtonFullWidth {
  width: 100%;
}

.splashImage {
  max-width: 650px;
  margin-top: 3rem;
}

.uTextCenter {
  text-align: center;
}

div[class*="ms-TextField-wrapper"]:after {
  border-bottom: 2px solid #6264a7;
}

.signinHeader {
  margin-bottom: 2.1rem;
}

.signinSubHeader {
  margin-top: 0 !important; /* needs to override Fabric */
  margin-bottom: 0.5rem;
}

/*
*  New Meeting Styles
*/

.newMeetingContainer {
  display: flex;
  height: calc(100vh - 10vh);
  flex-direction: column;
  justify-content: space-between;
  width: 100% !important;
}

.newMeetingInput input {
  border-radius: 3px;
}

.newMeetingInputIcon i {
  margin-right: 1.1rem;
}

div[class*="ms-ComboBox"]::after {
  border: none; /* override fabric border */
}

.newMeetingDatePicker {
  margin-right: 0.5rem;
}

.newMeetingDatePickerContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.newMeetingDatePickerContainer > i {
  margin-right: 1rem;
}

.newMeetingDatePicker i {
  display: none; /* hides fabric date icon to match design */
}

.newMeetingComboBox {
  flex: 0 0 30%;
}

.newMeetingPicker > div {
  margin-bottom: 0.8rem;
}

.newMeetingPickerIcon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  position: relative;
  font-size: 1.1rem;
  left: 15px;
  top: -8px;
}

.newMeetingPickerIncrement {
  position: relative;
  left: 15px;
  top: 7px;
}

@media (min-width: 600px) {
  .newMeetingPicker {
      display: flex;
  }

  .newMeetingComboBox {
      flex: 1 1 auto;
      max-width: 100px;
  }

  .newMeetingPickerIcon {
      padding: 0 0.5rem;
      left: auto;
  }
}

.newMeetingButtons {
  display: none;
}

.newMeetingButtonsMobile {
  display: block;
  padding: 0 1.5rem;
}

@media (min-width: 500px) {
  .newMeetingButtons {
      display: block;
  }

  .newMeetingButtonsMobile {
      display: none;
  }
}

/*
*  Meeting Card Styles
*/

.meetingSuccess {
  align-self: center;
  display: flex;
  margin-bottom: 1.5rem;
  width: 50px;
}

.teamsLink {
  color: #6264a7;
}

.meetingCardContainer {
  display: flex;
  box-shadow: 0px 9px 10px 1px #00000014;
  flex-direction: column;
  margin-top: 4.3rem;
  padding: 2rem;
}

@media (min-width: 600px) {
  .meetingCardContainer {
      width: 400px;
  }
}

.meetingCardBody {
  border: 1px solid #cccccc6b;
  border-radius: 3px;
  padding: 0 1rem 1.3rem 1rem;
}

.meetingCardBody > div > span {
  display: none; /* sigh...have to override injected html */
}

.meetingCardBody span {
  font-size: 0.9rem !important; /* override for injected html */
}

.meetingCardHeader,
.meetingCardSubHeader {
  margin-bottom: 1.4rem;
  text-align: center;
}

.meetingCardSubHeader {
  line-height: 1.5;
  max-width: 400px;
}

.meetingCardDialInfo,
.meetingCardUrl {
  margin-bottom: 1rem;
  display: block;
  font-size: 1rem;
}

.meetingCardConfID {
  margin-bottom: 1rem;
}

.copyButton {
  align-self: flex-end;
  margin-top: 1.6rem;
}

/*
* Spinner styling
*/
.spinnerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

div[class*="ms-Spinner-circle"] {
  border-color: #6264a7 #a7a8c7 #a7a8c7;
}

.ms-ComboBox-optionsContainerWrapper {
  max-height: 300px;
}

.wrapper-content-meet .DateInput, .DateInput input {
  width: 100%;
}

.wrapper-content-meet .SingleDatePicker, .wrapper-content-meet .SingleDatePickerInput {
  display: block;
}

.wrapper-content-meet .DateInput_input__focused, .wrapper-content-meet .DateInput_input {
  height: 37px;
  font-size: 12px;
}

/* New Styles */
.calendarTeams-Content {
  padding-bottom: 100px;
}

.calendarTeams-Content .rbc-toolbar .rbc-toolbar-label {
  text-align: left;
  text-transform: capitalize;
}

.content-meet--helptext {
  font-size: 11px;
  color: #808080;
  margin-top: -10px;
}

.content-meet--col {
  display: flex;
  grid-gap: 15px;
  gap: 15px;
  flex-wrap: wrap;
}

.content-meet--col .form-wizard-fields {
  /* flex: 1 1 auto; */
  width: 150px;
}

.date-contentIcon {
  color: #CCC;
  padding: 10px;
}

.label-dateMeet {
  display: block;
  font-size: 12px;
  margin-bottom: 0.5em;
}

.sizeInput-200 .css-bg1rzq-control {
  width: 200px;
}

.fix-textarea textarea {
  margin-top: 0 !important;
  margin-bottom: 10px;
}


.calendarTeams-Content .rbc-day-slot .rbc-event {
  left: 0 !important;
  width: 100% !important;
}

.calendarTeams-Content .rbc-day-slot .rbc-event:nth-child(2) {
  left: 10% !important;
  width: 90% !important;
}

.calendarTeams-Content .rbc-day-slot .rbc-event:nth-child(3) {
  left: 15% !important;
  width: 90% !important;
}

.calendarTeams-Content .rbc-day-slot .rbc-event-label {
  vertical-align: middle;
  font-weight: bold;
  font-size: 70%;
}

.calendarTeams-Content .rbc-day-slot .rbc-event-content {
  line-height: inherit;
  font-size: 70%;
}

.calendarTeams-Content .rbc-day-slot .rbc-event {
  border-radius: 2px;
  /* background-color: #DDDFF9 !important;
  color: #3D3E74;
  border: 1px solid #babde0; */

  background-color: #b5dddb !important;
  color: #2f5550;
  border: 1px solid #6fa4a2;
}

/*dialog style*/
.dialog-teams {
  position: fixed;
  z-index: 9999;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  width: 349px;

}

.modalContent-MeetNow {
  padding: 10px;
}

.modalContent-MeetNow button {
  width: 100%;
  margin-bottom: 8px;
  cursor: pointer;
}

.buttonTeams-Primary {
  background: #6164A6;
  color: #FFF;
  border: 1px solid #6164A6;
  border-radius: 4px;
  height: 30px;
}

.buttonTeams-Primary:hover {
  background: #4d5085;
}

.buttonTeams-Default {
  background: #FFF;
  color: #424242;
  border: 1px solid #E2E5E9;
  border-radius: 4px;
  height: 30px;
}

.buttonTeams-Default:hover {
  background: #efefef;
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-overlay.with-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}


.modalContent-MeetDetail {
  padding: 10px;
}

.modalContent-MeetDetail button {
  width: 100px;
  cursor: pointer;
}

/* Content Ppal */
.meetDetail-content {
  margin-bottom: 10px;
}

.meetDetail-content--title {
  font-size: 18px;
  font-weight: bold;
}

.meetDetail-content--date {
  font-size: 14px;
}


/* Aditional Information */
.meetDetail-aditionalInfo {
  margin-top: 10px;
  border-top: 1px solid #DDDCDD;
  padding-top: 10px;
}

.aditionalInfo-Organizer {
  list-style: none;
  font-size: 12px;
  margin-bottom: 10px;
  padding-left: 0;
}

.aditionalInfo-Organizer li {
  display: inline-block;
}

.aditionalInfo-Organizer li:last-child {
  color: #999;
}

.aditionalInfo-Organizer li:not(:last-child)::after {
  content: "-";
  display: inline-block;
  margin-left: .25rem;
  margin-right: .25rem;
  color: #999;
}


/* Aditional Information GUEST */
.aditionalInfo-Guest {
  list-style: none;
  font-size: 12px;
  padding-left: 0;
}

.aditionalInfo-Guest li {
  display: inline-block;
}

.aditionalInfo-Guest li:not(:last-child)::after {
  content: ",";
  display: inline-block;
  margin-right: .25rem;
}

li.aditionalInfo-Guest--title {
  display: block;
  color: #999;
}

li.aditionalInfo-Guest--title:not(:last-child)::after {
  content: none;
}


/* Modal successful */
.modalContent-Successful {
  text-align: center;
  padding: 2em;
}

.modalContent-Successful--button {
  text-align: right;
  margin-top: 2em;
}

.modalContent-Successful--button button {
  cursor: pointer;
  width: 80px;
}

.modalContent-Successful--desc {
  font-weight: bold;
  font-size: 14px;
  margin-top: 2em;
}

.no-scroll {
  overflow: hidden;
  padding-right: 15px;
}

.modal-is-loading {
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
}


/*.dialog style*/


.container-faq {
  max-width: 90%;
  padding: 2em;
  margin: auto;
  border-radius: 15px;
  background-color: #fff;
  height: 60vh;
  overflow-y: scroll;
}
/****************************/
.container-forgot-password {
  max-width: 325px; /*change*/
  padding: 2em;
  background-color: #798EAF;
  margin: auto;
  border-radius: 15px;
  text-align: center;
  font-size: 16px;
}

.icon-pass, .icon-pass-green {
  border-radius: 100%;
  border: 2px solid #FFF;
  padding: 1em;
  width: 25px;
  height: 25px;
  margin: auto;
  font-size: 25px;
  color: #FFF;
}

.icon-pass-green {
  border: 2px solid #63d697;
  color: #63d697;
}

/* Container */

.container-forgot-password input {
  border: 1px solid #FFF;
  padding: 12px 15px;
  font-size: 18px;
  box-sizing : border-box;
  width: 100%;
  margin: 0.5em 0;
  border-radius: 12px;
}

.container-forgot-password input::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
color: #798EAF;
opacity: 1; /* Firefox */
}

.container-forgot-password input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
color: #798EAF;
opacity: 1; /* Firefox */
}

.container-forgot-password input:-ms-input-placeholder { /* Internet Explorer 10-11 */
color: #798EAF;
}

.container-forgot-password input::-ms-input-placeholder { /* Microsoft Edge */
color: #798EAF;
}

.container-forgot-password .container-login_btn-login {
  margin-top: 1.5em;
}

.container-login_btn-login-secondary {
  font-weight: bold;
  display: block;
  border-radius: 4px;
  padding: 10px 0;
  font-size: 16px;
  text-align: center;
  margin: 0.5em auto;
  background-color: #27466c;
  border: 1px solid #27466c;
  color: #FFF;
  width: 100%;
  cursor: pointer;
}

.container-login_btn-login-secondary:hover {
  background-color: #b3bcbf;
  border: 1px solid #b3bcbf;
}


.container-forgot-password  .text-login {
  color: #FFF;
  margin: 1.5em auto;    
}

.important-message {
  background-color: #ffde9c;
  color: #233b58;
  font-size: 13px;
  padding: 1em;
  border-radius: 10px;
}

.important-message i {
  margin: 0.5em;
  display: block;
}



/****************************/
.container-forgot-password {
  max-width: 325px; /*change*/
  padding: 2em;
  background-color: #798EAF;
  margin: auto;
  border-radius: 15px;
  text-align: center;
  font-size: 16px;
}

.icon-pass, .icon-pass-green {
  border-radius: 100%;
  border: 2px solid #FFF;
  padding: 1em;
  width: 25px;
  height: 25px;
  margin: auto;
  font-size: 25px;
  color: #FFF;
}

.icon-pass-green {
  border: 2px solid #63d697;
  color: #63d697;
}

/* Container */

.container-forgot-password input {
  border: 1px solid #FFF;
  padding: 12px 15px;
  font-size: 18px;
  box-sizing : border-box;
  width: 100%;
  margin: 0.5em 0;
  border-radius: 12px;
}

.container-forgot-password input::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
color: #798EAF;
opacity: 1; /* Firefox */
}

.container-forgot-password input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
color: #798EAF;
opacity: 1; /* Firefox */
}

.container-forgot-password input:-ms-input-placeholder { /* Internet Explorer 10-11 */
color: #798EAF;
}

.container-forgot-password input::-ms-input-placeholder { /* Microsoft Edge */
color: #798EAF;
}

.container-forgot-password .container-login_btn-login {
  margin-top: 1.5em;
}

.container-login_btn-login-secondary {
  font-weight: bold;
  display: block;
  border-radius: 4px;
  padding: 10px 0;
  font-size: 16px;
  text-align: center;
  margin: 0.5em auto;
  background-color: #27466c;
  border: 1px solid #27466c;
  color: #FFF;
  width: 100%;
  cursor: pointer;
}

.container-login_btn-login-secondary:hover {
  background-color: #b3bcbf;
  border: 1px solid #b3bcbf;
}


.container-forgot-password  .text-login {
  color: #FFF;
  margin: 1.5em auto;    
}

.important-message {
  background-color: #ffde9c;
  color: #233b58;
  font-size: 13px;
  padding: 1em;
  border-radius: 10px;
}

.important-message i {
  margin: 0.5em;
  display: block;
}



.color-404 {
	color: #DC4771 !important;
}

.color-500 {
	color: #3BA5E4 !important;
}

.color-white {
	color: #FFF !important;
}

.full-col {
	width: 80%;
	margin:  auto;
}

.full-col__mod-txt {
	text-align: center;
	padding: 3em;
}

.full-col img {
	width: auto !important;
}

.bg-mantenimiento {
	background-color: #27466C;
	height: 100vh;
}

img.full-col__mod-txt-logo {
	width: 350px !important;
	max-width: 100% !important;
}

.full-col__mod-txt h1 {
	font-size: 30px;
	line-height: 1.2em;
	margin-top: 1.5em;
}

/****/

@media(min-width: 1100px) {
    #page > img {
		width: 65%;
	}
}

@media(max-width: 830px) {
	.DosCol .mod-txt {
		text-align: center;
	}
}

@media(max-width: 430px) {
    .mod-txt h1 {
		font-size: 25px;
		line-height: 1.2em;
	}
}
