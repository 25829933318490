/**
 * Created by c3om on 3/29/21.
 */
.header__icon-APP.pr-1 {
    padding-right: 1em;
}

 .user-comp_profile-wrapper {
    position: relative;
    top: 0;
}


.main-header {
    padding: 0.2em 1em;
}

.top-bar-menu {
    margin-right: 0 /*230px*/;
}

.user-comp_profile-wrapper {
    width: auto /*225px*/;
}

.user-comp_menu {
    top: 60px;
}

.double-border--principal {
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #6AD8D2 50%,#E88B19 50%);
}

.user-comp_info-user .double-border img {
    width: 87px;
    background: #FFF;
}

.user-comp_info-user .double-border {
    width: 100px;
    height: 100px;
    margin-bottom: 1em;
    background-image: linear-gradient(white, white), radial-gradient(circle at top left, #6AD8D2 50%,#E88B19 50%);
}

.user-comp_info-user h1 {
    font-weight: bold;
}

ul.user-comp_submenu {
    list-style: none;
}

ul.user-comp_submenu .nav-item svg {
    margin-right: 10px;
    color: #27466c
}

ul.user-comp_submenu .nav-item a:hover svg {
    color: #838383;
}

.help svg {
    margin-right: 5px;
}

.help-title {
    font-weight: 300;
}

.user-comp_submenu li {
    display: block;
}

.user-comp_menu {
    margin: -2px 0 0 -140px !important;
}

@media screen and (max-width: 750px) {
    .help {
        margin-right: 5px;
        font-size: 20px;
    }

    .top-bar-menu {
        margin-right: 0;
        font-size: 14px;
    }

    .user-comp_menu {
        margin: -2px 0 0 -118px !important;
    }
}
@media screen and (max-width: 500px) {
    .user-comp_profile-wrapper {
        width: auto /*225px*/ !important;
        right: 0 !important;
    }

    .user-comp_menu {
        margin: -2px 0 0 -145px !important;
    }
}
